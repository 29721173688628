<template>
	<section class="shopping-cart-step2">
		<div class="shopping-cart-step2-card">
			<div class="shopping-cart-step2-card-heading"><i class="fas fa-truck"></i> Spôsob dopravy</div>

			<div class="shopping-cart-step2-card-item" v-for="item in deliveryOptionsFiltered" :key="item.id" @click="changeDeliveryOption(item)">
				<input type="radio" name="delivery" v-model="delivery" :value="item.id">

				<div class="shopping-cart-step2-card-item-text">
					<div class="left" v-tooltip="item.help">
						<i :class="item.icon"></i> {{ item.label }}
					</div>

					<div class="right">
						{{ $parent.formatPrice(item ? item.price : 0) }}
					</div>
				</div>
			</div>
		</div>
		<div class="shopping-cart-step2-card">
			<div class="shopping-cart-step2-card-heading"><i class="fas fa-wallet"></i> Spôsob platby</div>

			<div class="shopping-cart-step2-card-item" v-for="item in paymentOptionsFiltered" :key="item.id" @click="changePaymentOption(item)">
				<input type="radio" name="payment" v-model="payment" :value="item.id">

				<div class="shopping-cart-step2-card-item-text">
					<div class="left" v-tooltip="item.help">
						<i :class="item.icon"></i> {{ item.label }}
					</div>

					<div class="right">
						{{ $parent.formatPrice(item ? item.price : 0) }}
					</div>
				</div>
			</div>
		</div>

		<div class="shopping-cart-step2-total">
			<div class="shopping-cart-step2-total-inner">
				<h2>Medzisúčet: <span>{{ $parent.formatPrice(getSubtotal) }}</span></h2>
				<h2>Doprava: <span>{{ $parent.formatPrice(getDelivery ? getDelivery.price : 0) }}</span></h2>
				<h2>Platba: <span>{{ $parent.formatPrice(getPayment ? getPayment.price : 0) }}</span></h2>
				<h1>Cena celkom: <span>{{ $parent.formatPrice(getTotal(false)) }}</span></h1>
			</div>
		</div>

		<div class="shopping-cart-step2-continue">
			<button type="button" role="button" @click="$parent.nextStep(2)">Pokračovať</button>
		</div>
	</section>
</template>

<script>
	import {mapGetters} from 'vuex';

	export default {
		name: "ShoppingCartStep2",
		data() {
			return {
				delivery: 'courier',
				payment: "online",
				deliveryOptions: null,
				paymentOptions: null,
				deliveryOptionsFiltered: null,
				paymentOptionsFiltered: null,
			};
		},
		computed: {
			...mapGetters([
				'getSubtotal', 'getTotal', 'getPayment', 'getDelivery', 'getPaymentOptions', 'getDeliveryOptions', 'getCartProductsCountItems',
			])
		},
		watch: {
			delivery: {
				deep: true,
				handler: function () {
					this.reloadPaymentOptions();
				},
			},
		},
		mounted() {
			this.loadOptions();
		},
		methods: {
			loadOptions() {
				//Get from storage:
				this.deliveryOptions = this.getDeliveryOptions;
				this.paymentOptions  = this.getPaymentOptions;

				//Init array:
				this.deliveryOptionsFiltered = [];

				//Delivery options are filtered by total price:
				let item;
				for(let i in this.deliveryOptions) {
					//Get item:
					item = this.deliveryOptions[i];

					//Item price set?
					if(item.min_products == 0 && item.max_products == 0) {
						this.deliveryOptionsFiltered.push(item);
					} else if(item.min_products > 0 || item.max_products > 0) {
						if(this.getCartProductsCountItems > item.min_products && this.getCartProductsCountItems <= item.max_products) {
							this.deliveryOptionsFiltered.push(item);
						}
					}
				}

				//Set first option:
				this.changeDeliveryOption(this.deliveryOptionsFiltered[0]);
			},
			resetOptions() {
				//Get payment option or set first:
				this.changePaymentOption(this.getPayment ? this.getPayment : this.paymentOptions[0]);

				//Get delivery option or set first:
				this.changeDeliveryOption(this.getDelivery ? this.getDelivery : this.deliveryOptions[0]);

				//Reload payment options:
				this.reloadPaymentOptions();
			},
			changeDeliveryOption(item) {
				this.delivery = item.id;
				this.$store.commit('setDelivery', item);
			},
			changePaymentOption(item) {
				this.payment = item.id;
				this.$store.commit('setPayment', item);
			},
			reloadPaymentOptions() {
				this.paymentOptionsFiltered = this.paymentOptions.filter(payment => (
					payment.is_personal === this.getDelivery.is_personal &&
					(
						(payment.min_order_value == 0 && payment.max_order_value == 0)
						||
						(
							(this.getSubtotal + Number(this.getDelivery.price) + Number(payment.price)) >= payment.min_order_value &&
							(this.getSubtotal + Number(this.getDelivery.price) + Number(payment.price)) < payment.max_order_value)
						)
					)
				);

				this.changePaymentOption(this.paymentOptionsFiltered[0]);
			},
		},
	}
</script>

<style scoped lang="scss">
	.shopping-cart {
		&-step2 {
			width: 100%;

			$step2-color  : rgba($color-text-dark, 0.15);
			$step2-color-2: rgba($color-text-dark, 0.5);
			$item-height: 60px;
			$input-size: 20px;

			&-card {
				width: 100%;
				display: block;
				margin: 0;
				padding: 0;

				&-heading {
					@include font-primary();
					display: block;
					font-size: $font-size-bigger;
					margin: 0;
					padding: 0 20px;
					line-height: 70px;
					background: $step2-color;

					i {
						margin-right: 15px;
						color: $color-primary;
					}
				}

				&-item {
					display: block;
					border: 1px solid $step2-color;
					height: $item-height;
					line-height: $item-height;
					cursor: pointer;
					padding: 0 20px;
					font-size: $font-size-basic;

					input[type="radio"] {
						display: inline-block;
						float: left;
						width: 20px;
						height: 20px;
						margin: (($item-height - $input-size) / 2) 0;
						border: 1px solid $step2-color-2;
						padding: 0;

						&:checked {
							position: relative;
							border-color: $color-primary;
							/*background: rgba($color-primary, 0.1);*/

							&:after {
								content: "";
								position: absolute;
								top: 1px;
								left: 6px;
								width: 6px;
								height: 12px;
								display: block;
								transform: rotateZ(45deg);
								border-bottom: 1px solid $color-primary;
								border-right: 1px solid $color-primary;
							}
						}
					}

					&-text {
						margin: 0 0 0 30px;
						float: left;
						display: inline-block;
						width: calc(100% - 50px);

						i {
							margin-right: 8px;
						}

						.right {
							float: right;
							display: inline-block;
							color: $color-primary;
							font-size: $font-size-bigger;
							font-weight: 400;
							text-transform: uppercase;
						}

						.left {
							float: left;
							display: inline-block;
						}
						
						small {
							margin-left: 15px;
							color: lighten($color-dark, 50%);
						}
					}
				}

				@include media-breakpoint-down(md) {
					&-heading {
						font-size: $font-size-bigger;
						padding: 0 15px;
					}

					&-item {
						font-size: $font-size-basic-lg;
						padding: 0 15px;

						&-text {
							width: calc(100% - 30px);
							margin: 0 0 0 10px;

							.right {
								font-size: $font-size-basic-lg;
							}
						}
					}
				}
			}

			&-total {
				width: 100%;
				margin: 0;

				&-inner {
					float: right;
					display: block;
					overflow: visible;
					padding: 0 20px;
					margin: 30px 0 0;

					h1, h2 {
						@include font-primary();
						font-size: $font-size-bigger;
						color: $color-dark;
						display: block;
						width: 300px;
						text-align: left;

						span {
							@include font-primary(500);
							width: 150px;
							font-size: $font-size-heading-1;
							color: $color-primary;
							display: inline-block;
							text-align: right;
							float: right;
						}
					}

					h2 {
						span {
							font-weight: 400;
							font-size: $font-size-heading-1;
						}
					}

					@include media-breakpoint-down(md) {
						width: 100%;
						padding: 0 10px;
						float: none;

						h1, h2 {
							width: 100%;
							text-align: left;
							font-size: $font-size-basic-lg;

							span {
								width: auto;
								font-size: $font-size-bigger;
							}
						}
					}
				}
			}

			&-continue {
				width: 100%;
				overflow: auto;

				button, a {
					background: $color-primary;
					color: $color-text-white;
					padding: 12.5px 30px;
					@include font-primary(400);
					font-size: $font-size-bigger;
					text-transform: uppercase;
					float: right;
					border: none;
					outline: none;
					text-decoration: none;
					@include theme-transition();

					&:hover {
						background: $color-primary-hover;
					}
				}
			}
		}
	}
</style>