<template>
	<div class="blog-sidebar">
		<h2>Kategórie</h2>
		<ul>
			<li v-for="category in getCategories" :key="category.id" @click="setCategoryFilter(category.id)">{{ category.name }} <sup>({{ category.counter }})</sup></li>
			<li @click="removeCategoryFilter">Všetky</li>
		</ul>
		
		<h2>Posledné príspevky</h2>
		<div class="blog-sidebar-lastposts">
			<BlogPostLast v-for="post in getPosts.slice(0, 3)" :post="post" :key="post.id" />
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	import BlogPostLast from "../../components/BlogPostLast";

	export default {
		name: "BlogSidebar",
		components: {BlogPostLast},
		computed: {
			...mapGetters([
				'getPosts', 'getCategories',
			]),
		},
		methods: {
			setCategoryFilter(id) {
				this.$store.commit('setBlogFilter', {
					category_id: id,
				});
			},
			removeCategoryFilter() {
				this.$store.commit('setBlogFilter', {
					category_id: 0,
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	.blog-sidebar {
		$sidebar-margin: 50px;
		
		width: 300px;
		margin-right: $sidebar-margin;
		overflow: auto;
		position: fixed;
		
		@include media-breakpoint-down(md) {
			width: 100%;
			margin-right: 0;
			margin-top: 0;
			position: relative;
		}
		
		h2 {
			@include font-headings();
			font-size: $font-size-basic-lg;
			text-transform: uppercase;
			margin-bottom: 5px;
		}
		
		ul {
			list-style: none;
			overflow: auto;
			margin: 0 0 30px 0;
			padding: 0;
			
			li {
				margin: 0 0 3px 0;
				padding: 0;
				cursor: pointer;
				
				sup {
					margin-left: 5px;
					color: $color-primary;
				}
			}
		}
		
		&-lastposts {
			width: 100%;
			overflow: auto;
		}
	}
</style>