import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter);

import Index from "../views/Index";
import Products from "../views/Products";
import Contact from "../views/Contact";
import Login from "../views/Login";
import ProductDetail from "../views/ProductDetail";
import ShoppingCart from "../views/ShoppingCart";
import ShoppingCartStep1 from "../views/Cart/ShoppingCartStep1";
import ShoppingCartStep2 from "../views/Cart/ShoppingCartStep2";
import ShoppingCartStep3 from "../views/Cart/ShoppingCartStep3";
import ShoppingCartStep4 from "../views/Cart/ShoppingCartStep4";
import ShoppingCartStep5 from "../views/Cart/ShoppingCartStep5";
import SalesPoints from "../views/SalesPoints";
import Page from "../views/Page";
import Page404 from "../views/Page404";
import Profile from "../views/Profile";
import PasswordRecovery from "@/views/PasswordRecovery";
import ProfileOrders from "../views/ProfileOrders";
import Blog from "../views/Blog";
import BlogPost from "../views/Blog/BlogPost";
import BlogPostsList from "../views/Blog/BlogPostsList";

const routes = [
	{
		path: "/",
		name: "index",
		component: Index,
		meta: {
			title: "Domov",
		}
	},
	{
		path: "/e-shop",
		name: "eshop",
		component: Products,
		meta: {
			title: "E-shop",
		}
	},
	{
		path: "/blog",
		name: "blog",
		component: Blog,
		children: [
			{
				path: "/blog",
				name: "",
				component: BlogPostsList,
				meta: {
					title: "Blog",
				}
			},
			{
				path: "/blog/:id",
				name: "blog-post",
				component: BlogPost,
			}
		],
	},
	{
		path: "/produkt/:id",
		name: "product",
		component: ProductDetail,
	},
	{
		path: "/kontakt",
		name: "contact",
		component: Contact,
		meta: {
			title: "Kontakt",
		}
	},
	{
		path: "/kosik",
		component: ShoppingCart,
		children: [
			{
				path: '',
				redirect: {
					name: "shopping-cart-contents"
				}
			},
			{
				path: "/kosik/obsah",
				name: "shopping-cart-contents",
				component: ShoppingCartStep1,
				meta: {
					title: "Košík",
				}
			},
			{
				path: "/kosik/doprava",
				name: "shopping-cart-transport",
				component: ShoppingCartStep2,
				meta: {
					title: "Košík",
				}
			},
			{
				path: "/kosik/dorucenie",
				name: "shopping-cart-address",
				component: ShoppingCartStep3,
				meta: {
					title: "Košík",
				}
			},
			{
				path: "/kosik/zhrnutie",
				name: "shopping-cart-overview",
				component: ShoppingCartStep4,
				meta: {
					title: "Košík",
				}
			},
			{
				path: "/kosik/hotovo",
				name: "shopping-cart-done",
				component: ShoppingCartStep5,
				meta: {
					title: "Ďakujeme za nákup",
				}
			}
		],
	},
	{
		path: "/prihlasenie",
		name: "login",
		component: Login,
		meta: {
			title: "Prihlásenie",
		}
	},
	{
		path: "/kde-kupit",
		name: "sales-points",
		component: SalesPoints,
		meta: {
			title: "Kde kúpiť?",
		}
	},
	{
		path: "/profil/objednavky",
		name: "profile-orders",
		component: ProfileOrders,
		meta: {
			title: "Objednávky",
		}
	},
	{
		path: "/profil",
		name: "profile",
		component: Profile,
		meta: {
			title: "Profil",
		}
	},
	{
		path: "/obnovit-heslo",
		name: "password-recovery",
		component: PasswordRecovery,
		meta: {
			title: "Obnoviť heslo",
		}
	},
	{
		path: "/404",
		name: "404",
		component: Page404,
		meta: {
			title: "Chyba 404",
		}
	},
	{
		path: "/:page",
		name: "page",
		component: Page,
	},
	{
		path: "*",
		redirect: {
			name: "404",
		}
	}
];

export default new VueRouter({
	mode: 'history',
	linkActiveClass: 'active',
	linkExactActiveClass: 'active',
	scrollBehavior () {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({ x: 0, y: 0 })
			}, 200)
		})
	},
	routes,
});
