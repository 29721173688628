<template>
	<div class="product-card" :class="{'darken': isDarkened(index)}">
		<div class="container">
			<div class="row">
				<div class="product-card-image">
					<router-link :to="'/produkt/' + product.id">
						<ImgLazy :url="imageSrc(product.img_thumb)" :alt="product.name" />
					</router-link>
				</div>
				
				<div class="product-card-description">
					<div class="product-card-description-inside">
						<h2>{{ product.category_name }}</h2>
						<h1>{{ product.name }}</h1>
						
						<div class="product-card-description-inside-text" v-html="product.description_short" />
						
						<router-link class="product-card-description-inside-buy" :to="{name: 'product', params: {id: product.id }}">Prejsť do e-shopu</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ImgLazy from "./ImgLazy";
	export default {
		name: "IndexProductCard",
		components: {ImgLazy},
		props: {
			product: {
				type: Object,
				required: true,
			},
			index: {
				type: Number,
				required: true,
			}
		},
		methods: {
			isDarkened(id) {
				return (id + 1) % 2 === 0;
			}
		},
	}
</script>

<style scoped lang="scss">
	.product-card {
		background: darken($body-bg, 0%);
		padding: 25px 0;
		margin-left: 0;
		margin-right: 0;
		
		$image-size: 320px;
		$image-size-md: 190px;

		&.darken {
			//background: darken($body-bg, 5%);
		}
	
		&-image {
			float: left;
			width: $image-size;
			height: $image-size;
			text-align: center;
			
			img {
				height: 100%;
				max-width: $image-size;
			}
		}
		
		&-description {
			float: left;
			width: calc(100% - #{$image-size});
			height: $image-size;
			position: relative;
			
			&-inside {
				margin-left: 50px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				font-size: $font-size-basic-lg;
				
				&-text {
					width: 100%;
					display: block;
					margin-bottom: 35px;
				}
				
				&-buy {
					padding: 12px 35px;
					background: $color-dark;
					color: $color-light;
					text-transform: uppercase;
					font-weight: 500;
					font-size: $font-size-basic-sm;
					@include theme-transition();
					
					&:hover, &:visited, &:active {
						text-decoration: none;
						color: $color-light;
					}
					
					&:hover {
						background: $color-primary;
					}
				}
				
				h1 {
					color: $color-dark;
					text-transform: uppercase;
					font-size: $font-size-heading-4;
				}
				
				h2 {
					color: $color-primary;
					font-size: $font-size-heading-1;
					font-weight: 300;
				}
			}
		}

		@include media-breakpoint-up(md) {
			&.darken {
				direction: rtl;
			}
			
			&.darken &-image {
				direction: ltr;
			}
		
			&.darken &-description {
				direction: ltr;
				text-align: right;
			}
		}
		
		@include media-breakpoint-down(md) {
			padding: 25px 10px;
			
			.row {
				margin-left: 0;
				margin-right: 0;
			}
			
			&-image {
				width: 100%;
				height: $image-size-md;
			}
			
			&-description {
				width: 100%;
				height: auto;
				padding: 25px 0;
				
				&-inside {
					margin-left: 0;
					position: relative;
					top: 0;
					transform: none;
				}
			}
		}
	}
</style>