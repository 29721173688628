<template>
	<GmapMap style="width: 100%; height: 100%;" :center="{lat: 48.305542, lng: 17.285684}" :zoom="12" :options="google_maps" v-if="markers.length > 0">
		<GmapMarker v-for="(marker, key) in markers" :position="marker.position" :label="marker.label" :key="key"/>
	</GmapMap>
</template>

<script>
	import {gmapApi} from "vue2-google-maps";
	
	export default {
		name: "DarkGoogleMaps",
		data() {
			let styles = [
				{ elementType: "geometry", stylers: [{ color: "#242f3e" }] },
				{ elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
				{ elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
				{
					featureType: "administrative.locality",
					elementType: "labels.text.fill",
					stylers: [{ color: "#d59563" }],
				},
				{
					featureType: "poi",
					elementType: "labels.text.fill",
					stylers: [{ color: "#d59563" }],
				},
				{
					featureType: "poi.park",
					elementType: "geometry",
					stylers: [{ color: "#263c3f" }],
				},
				{
					featureType: "poi.park",
					elementType: "labels.text.fill",
					stylers: [{ color: "#6b9a76" }],
				},
				{
					featureType: "road",
					elementType: "geometry",
					stylers: [{ color: "#38414e" }],
				},
				{
					featureType: "road",
					elementType: "geometry.stroke",
					stylers: [{ color: "#212a37" }],
				},
				{
					featureType: "road",
					elementType: "labels.text.fill",
					stylers: [{ color: "#9ca5b3" }],
				},
				{
					featureType: "road.highway",
					elementType: "geometry",
					stylers: [{ color: "#746855" }],
				},
				{
					featureType: "road.highway",
					elementType: "geometry.stroke",
					stylers: [{ color: "#1f2835" }],
				},
				{
					featureType: "road.highway",
					elementType: "labels.text.fill",
					stylers: [{ color: "#f3d19c" }],
				},
				{
					featureType: "transit",
					elementType: "geometry",
					stylers: [{ color: "#2f3948" }],
				},
				{
					featureType: "transit.station",
					elementType: "labels.text.fill",
					stylers: [{ color: "#d59563" }],
				},
				{
					featureType: "water",
					elementType: "geometry",
					stylers: [{ color: "#17263c" }],
				},
				{
					featureType: "water",
					elementType: "labels.text.fill",
					stylers: [{ color: "#515c6d" }],
				},
				{
					featureType: "water",
					elementType: "labels.text.stroke",
					stylers: [{ color: "#17263c" }],
				},
			]
			
			return {
				placeId: '',
				geocoder: null,
				google_maps: {
					disableDefaultUI: !this.$props.controls,
					fullscreenControl: false,
					streetViewControl: false,
					mapTypeControl: false,
					styles: this.dark ? styles : [],
				},
				markers: [],
			};
		},
		props: {
			places: {
				type: Array,
				required: true,
			},
			controls: {
				type: Boolean,
				default: true,
			},
			dark: {
				type: Boolean,
				default: false,
			},
			show: {
				type: Boolean,
				required: false,
				default: true,
			}
		},
		computed: {
			google: gmapApi,
		},
		watch: {
			show: function () {
				if(this.show) {
					this.loadAPI();
				}
			},
		},
		mounted() {
			if(this.show) {
				this.loadAPI();
			}
		},
		methods: {
			loadAPI() {
				let _that = this;
				this.$gmapApiPromiseLazy().then(() => {
					if(_that.google) {
						//Initialize geocoder:
						_that.geocoder = new _that.google.maps.Geocoder();
						
						//Initialize places:
						_that.loadPlaces();
					}
				});
			},
			loadPlaces() {
				for(let i in this.places) {
					this.geocode(this.places[i]);
				}
			},
			geocode(place) {
				let _that = this;
				this.geocoder.geocode({address: place.address}, (results) => {
					if (results && results[0]) {
						_that.markers.push({
							position: {
								lat: results[0].geometry.location.lat(),
								lng: results[0].geometry.location.lng(),
							},
							label: place.label,
						});
					}
				})
			}
		}
	}
</script>
