<template>
	<section class="sales-points-group">
		<h1>{{ group.title }}</h1>

		<div class="row">
			<SalePoint v-for="point in group.points" :key="point.id" :point="point" />
		</div>
	</section>
</template>

<script>
	import SalePoint from "./SalePoint";
	export default {
		name: "SalesPointsGroup",
		components: {SalePoint},
		props: {
			group: {
				type: Object,
				required: true,
			}
		},
	}
</script>

<style scoped lang="scss">
	.sales-points-group {
		width: 100%;
		overflow: auto;
		padding: 0;
		margin: 0;

		h1 {
			text-transform: uppercase;
		}

		.row {
			width: 100%;
		}
	}
</style>
