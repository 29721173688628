<template>
	<main role="main" class="container">
		<section class="profile-orders">
			<h1>Moje objednávky</h1>
			
			<div class="table-responsive">
				<table class="table">
					<thead>
						<tr class="orders-header">
							<th>Číslo objednávky</th>
							<th>Objednané</th>
							<th>Stav</th>
							<th>Platba</th>
							<th>Doručenie</th>
							<th>Cena celkom</th>
							<th></th>
						</tr>
					</thead>
					<ProfileOrder v-for="order in orders" :order="order" :key="order.id" />
				</table>
			</div>
		</section>
	</main>
</template>

<script>
import {mapGetters} from 'vuex';
import api from "@/services/api";
import ProfileOrder from "../components/ProfileOrder";

export default {
	name: "ProfileOrders",
	components: {ProfileOrder},
	data() {
		return {
			orders: [],
			page: 1,
			openedOrders: {},
		};
	},
	computed: {
		...mapGetters([
			'getCustomerId',
		]),
	},
	watch: {
		getCustomerId: function () {
			this.loadData();
		},
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData() {
			let _that = this;
			
			api.getOrders().then(res => {
				_that.orders = res.data.orders;
				
				for(let i in _that.orders) {
					_that.openedOrders[_that.orders[i].id] = false;
				}
			});
		},
	},
}
</script>

<style scoped lang="scss">
	.profile-orders {
		.table-responsive {
			@include scrollbar-variant-horizontal($body-bg, rgba($color-primary, 0.8), 5px, 3px);
		}
	}

	.orders {
		width: 100%;
		list-style: none;
		margin: 0;
		padding: 0;
		
		&-header {
			@include font-headings();
			color: $color-primary;
			font-size: $font-size-basic-lg;
			text-align: center;
			
			th {
				font-weight: 300;
			}
			
			th:first-child {
				width: 120px;
			}
		}
	}
</style>