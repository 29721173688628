<template>
	<div class="shopping-cart-item" :class="{'no-border': last}">
		<div class="shopping-cart-item-left">
			<div class="shopping-cart-item-remove" @click="removeFromBasket" v-if="deletable">
				<i class="fas fa-times"></i>
			</div>

			<div class="shopping-cart-item-thumb">
				<ImgLazy :url="imageSrc(item.product.img_thumb)" :alt="item.product.name" />
			</div>
		</div>

		<div class="shopping-cart-item-row">
			<div class="shopping-cart-item-left shopping-cart-item-info">
				<div class="shopping-cart-item-name">
					<h1>{{ item.product.name }}</h1>
					<h2>{{ item.product.year }}</h2>
				</div>
			</div>

			<div class="shopping-cart-item-right shopping-cart-item-info">
				<div class="shopping-cart-item-price">
					<strong>{{ formatPrice(item.product.price) }}</strong> / ks
				</div>

				<div class="shopping-cart-item-quantity" v-if="editable">
					<button role="button" type="button" class="shopping-cart-item-quantity-plus" @click="quantityPlus">
						<i class="fas fa-plus"></i>
					</button>

					<div class="shopping-cart-item-quantity-input">
						<input type="number" min="1" v-model="amount" />
					</div>

					<button role="button" type="button" class="shopping-cart-item-quantity-minus" @click="quantityMinus">
						<i class="fas fa-minus"></i>
					</button>
				</div>
				<div class="shopping-cart-item-quantity" v-else>
					<div class="shopping-cart-item-quantity-input">
						<input type="number" min="1" v-model="amount" disabled="disabled"/>
					</div>
				</div>

				<div class="shopping-cart-item-total">
					{{ formatPrice(totalPrice) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	import ImgLazy from "./ImgLazy";

	export default {
		name: "ProductCartItem",
		components: {ImgLazy},
		data() {
			return {
				itemAmount: 1,
			};
		},
		props: {
			item: {
				type: Object,
				required: true,
			},
			deletable: {
				type: Boolean,
				required: false,
				default: true,
			},
			editable: {
				type: Boolean,
				required: false,
				default: true,
			},
			last: {
				type: Boolean,
				required: false,
				default: false,
			},
		},
		computed: {
			totalPrice: function () {
				return this.amount * this.item.product.price;
			},
			amount: {
				set: function (val) {
					this.itemAmount = val;
					this.$store.dispatch('updateCartProduct', {
						amount: this.amount,
						product: this.item.product,
					});
				},
				get: function () {
					return this.itemAmount;
				},
			},
			...mapGetters([
				'getCartProduct'
			]),
		},
		mounted() {
			//Load amount:
			this.reloadAmount();
			
			//Watch if reload:
			this.$parent.$on("reloadProducts", this.reloadAmount);
		},
		methods: {
			quantityPlus() {
				this.amount++;
			},
			quantityMinus() {
				this.amount = (this.amount > 1) ? this.amount - 1 : 1;
			},
			reloadAmount() {
				this.amount = this.getCartProduct(this.item).amount;
			},
			removeFromBasket() {
				this.$store.dispatch('removeCartProduct', {
					product: this.item.product,
				});
			},
			formatPrice: function (price) {
				//Two decimals:
				price = (Math.round(price * 100) / 100).toFixed(2) + "€";

				//Return formatted price:
				return price;
			},
		},
	}
</script>

<style scoped lang="scss">
	.shopping-cart {
		&-item {
			border-bottom: 1px solid $color-light-1;
			height: 140px;
			padding: 20px 0;
			width: 100%;
			display: inline-block;

			&.no-border {
				border-bottom: none;
			}

			&-left {
				display: inline-block;
				float: left;
			}

			&-right {
				display: inline-block;
				float: right;
			}

			&-remove {
				cursor: pointer;
				position: relative;
				height: 30px;
				margin: 35px 0;
				padding: 0;
				width: 30px;
				line-height: 30px;
				text-align: center;
				color: $color-light-2;
				float: left;

				&:hover {
					color: $color-primary-hover;
				}
			}

			&-thumb {
				height: 90px;
				margin: 5px;
				float: left;
				line-height: 90px;
				text-align: center;
				width: 90px;

				img {
					max-width: 100%;
					max-height: 100%;
				}
			}

			&-name {
				height: 90px;
				float: left;
				margin: 5px 5px 5px 50px;
				display: flex;
				flex-direction: column;
				justify-content: center;

				h1, h2 {
					@include font-primary();
					margin: 0;
				}

				h1 {
					font-size: $font-size-heading-1;
				}

				h2 {
					font-size: $font-size-basic-lg;
					color: $color-text-dark;
				}
			}

			&-quantity {
				height: 30px;
				margin: 35px 0 ;
				padding: 0;
				line-height: 30px;
				display: inline-block;
				width: 170px;
				float: left;
				text-align: right;

				&-input {
					display: inline-block;
					float: right;

					input {
						border: 1px solid rgba($color-dark, 0.2);
						display: block;
						width: 30px;
						text-align: center;
						margin: 0 8px;
						outline: none;
						@include theme-transition();

						&::-webkit-inner-spin-button,
						&::-webkit-outer-spin-button {
							-webkit-appearance: none;
							margin: 0;
						}

						&:focus {
							outline: none;
							color: $color-dark;
							border-color: $color-primary-hover;
						}

						&:disabled {

						}
					}
				}

				&-plus, &-minus {
					display: inline-block;
					float: right;
					line-height: 32px;
					outline: none;
					border: none;
					background: $color-light;
					color: $color-dark;
					font-size: $font-size-small;

					&:hover {
						color: $color-primary-hover;
					}

					&:hover, &:focus {
						outline: none;
						border: none;
						box-shadow: none;
					}
				}
			}

			&-price {
				height: 90px;
				margin: 5px;
				float: left;
				line-height: 90px;
				width: 100px;
				text-align: right;

				strong {
					font-size: $font-size-bigger;
					font-weight: 300;
				}
			}

			&-total {
				height: 90px;
				margin: 5px;
				float: left;
				line-height: 90px;

				width: 150px;
				text-align: right;
				font-size: $font-size-bigger;
				color: $color-primary;
				font-weight: 500;
			}

			@include media-breakpoint-down(md) {
				.shopping-cart-item {
					&-remove {
						width: 20px;
					}

					&-thumb {
						height: 90px;
						margin: 5px;
						float: left;
						line-height: 90px;
						text-align: center;
						width: 50px;

						img {
							max-width: 100%;
							max-height: 100%;
						}
					}
				}

				&-row {
					width: calc(100% - 90px);
					float: left;
					position: relative;

					.shopping-cart-item {
						&-info {
							width: 100%;
						}

						&-name {
							height: 60px;
							width: 190px;
							display: block;
							line-height: 30px;
							margin: 0 0 0 10px;

							h1 {
								font-size: $font-size-bigger;
							}

							h2 {
								font-size: $font-size-basic-sm;
							}
						}

						&-price {
							position: absolute;
							bottom: 30px;
							right: 0;
							width: auto;
							height: 30px;
							margin: 0;

							line-height: 30px;
							float: left;
							font-size: $font-size-small;

							strong {
								font-size: $font-size-basic-sm;
							}
						}

						&-quantity {
							float: left;
							width: auto;
							margin: 10px 0 0 10px;
							height: 30px;
							line-height: 30px;


							&-input {
								input {
									width: 26px;
									height: 26px;
									line-height: 26px;
									margin: 0 4px;
								}
							}

							&-plus, &-minus {
								line-height: 28px;
								height: 28px;
								width: auto;
								margin: 0;
								padding: 0;
							}
						}

						&-total {
							margin: 10px 0 0 10px;
							height: 30px;
							line-height: 30px;
							width: auto;
							float: right;
							font-size: $font-size-bigger;
						}
					}
				}
			}
		}
	}
</style>
