<template>
	<main role="main" class="container">
		<section class="login">
			<div class="login-customer">
				<h1>Prihlásenie zákazníka</h1>
				<h2>Zadajte svoje prihlasovacie údaje.</h2>

				<form @submit.prevent="login()" class="login-customer-form" enctype="multipart/form-data" method="post">
					<div class="form-item">
						<input type="email" name="login_email" placeholder="* E-mail" v-model="email">
					</div>

					<div class="form-item">
						<input type="password" name="login_password" placeholder="* Heslo" v-model="password">
					</div>

					<div class="form-item">
						<button type="submit" role="button" @click.prevent="login()">Prihlásiť sa</button>
					</div>
				</form>
			</div>

			<div class="login-divider"></div>

			<div class="login-register">
				<h1>Zabudnuté heslo?</h1>
				<h2>Zadajte e-mail nižšie a my Vám pošleme nové heslo.</h2>

				<form @submit.prevent="recovery()" class="recovery-customer-form" enctype="multipart/form-data" method="post">
					<div class="form-item">
						<input type="email" name="login_email" placeholder="* E-mail" v-model="email_recovery">
					</div>

					<div class="form-item">
						<button type="submit" role="button" @click.prevent="recovery()">Resetovať heslo</button>
					</div>
				</form>
			</div>
		</section>
	</main>
</template>

<script>
	import api from "../services/api";
	import {mapGetters} from "vuex";

	export default {
		name: "Login",
		data() {
			return {
				password: "",
				email: "",
				email_recovery: "",
			};
		},
		computed: {
			...mapGetters([
				'getRedirAfterLogin', 'getCustomerId'
			]),
		},
		watch: {
			getCustomerId: function () {
				if(this.getCustomerId > 0) {
					this.$router.push({
						name: 'profile'
					});
				}
			}
		},
		methods: {
			login() {
				//Create data object:
				let data = {
					email: this.email,
					password: this.password,
				};

				//Request:
				let _that = this;
				api.postCustomer(data).then(res => {
					_that.$store.commit('setCustomer', res.data.customer);
					
					if(res.data.customer) {
						if(this.getRedirAfterLogin) {
							_that.$router.push(this.getRedirAfterLogin).catch(() => {});
						} else {
							_that.$router.push('/').catch(() => {});
						}
					} else {
						_that.$swal.fire("Chyba!", "E-mail alebo heslo nie je správne!", "warning");
					}
				});
			},
			recovery() {
				//Create data object:
				let data = {
					email: this.email_recovery,
				};
				
				//Request:
				let _that = this;
				api.resetCustomerPassword(data).then(res => {
					if(res.data.ok) {
						_that.$swal.fire("Odoslané!", "Žiadosť o obnovenie hesla bola úspešne odoslaná.", "success");
					} else {
						_that.$swal.fire("Chyba!", "Nastala chyba na strane servera!", "warning");
					}
				});
			}
		},
	}
</script>

<style scoped lang="scss">
	.login {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		> div {
			float: left;
			padding: 40px 0;
			text-align: center;
			flex: 1;
		}

		.form-item {
			margin: 0 auto 20px auto;
			width: 70%;
			display: block;

			input {
				@include font-primary();
				font-size: $font-size-basic;
				padding: 10px 12px;
				width: 100%;
				border: 1px solid $color-dark;
				outline: none;
				@include theme-transition();

				&:focus {
					border-color: $color-primary;
				}
			}

			button, a {
				background: $color-primary;
				color: $color-text-white;
				padding: 12.5px 30px;
				@include font-primary();
				font-size: $font-size-basic;
				text-transform: uppercase;
				border: none;
				outline: none;
				text-decoration: none;
				@include theme-transition();

				&:hover {
					background: $color-primary-hover;
				}
			}
		}

		h1, h2 {
			margin: 0;
			padding: 0;
		}

		h1 {
			font-size: $font-size-heading-2;
			margin-bottom: 10px;
		}

		h2 {
			font-size: $font-size-basic-lg;
			color: lighten($color-text-dark, 1%);
			margin-bottom: 25px;
		}

		&-customer {
			overflow: visible;
			position: relative;
		}

		&-divider {
			width: 2px;
			padding: 0;
			background: $color-primary;
			flex: unset !important;
			align-self: stretch;
		}

		&-register {
			.form-item {
				margin-top: 30px;
			}
		}

		@include media-breakpoint-down(md) {
			display: block;

			> div {
				width: 100%;
				flex: unset;
			}

			&-divider {
				padding: 0 !important;
				margin: 0;
				height: 2px;
			}
		}
	}
</style>
