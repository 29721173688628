import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-awesome/icons'
import LineAwesomeIcon from "./components/Typography/LineAwesomeIcon";
import Notifications from 'vue-notification'
import VueCarousel from 'vue-carousel';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueLodash from 'vue-lodash'
import VTooltip from 'v-tooltip'
import lodash from 'lodash'
import VueLazyload from 'vue-lazyload'
import DisableAutocomplete from 'vue-disable-autocomplete';

Vue.config.productionTip = false
Vue.use(VueLodash, {lodash: lodash})
Vue.component("LineAwesome", LineAwesomeIcon);
Vue.use(Notifications);
Vue.use(VueCarousel);
Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(VTooltip);
Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyCApFkjUyTROn1h6hHVmKb5WmTIrmd61_4',
		libraries: 'places',
        language: "sk",
	},
	installComponents: true,
});
Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: 'dist/img/img_error.png',
	loading: 'dist/img/img_loading.gif',
	attempt: 1
})
Vue.use(DisableAutocomplete);
Vue.mixin({
	methods: {
		imageSrc(directory) {
			return (process.env.NODE_ENV === 'development' ? process.env.VUE_APP_ASSETS_LOCAL_URL : process.env.VUE_APP_ASSETS_URL) + directory;
		}
	}
});

new Vue({
	router,
	store,
	render: function (h) {
		return h(App)
	}
}).$mount('#app')
