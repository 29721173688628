<template>
	<div class="blog-post-thumbnail">
		<div class="blog-post-thumbnail-heading">
			<h2>{{ post.category.name }} / {{ post.author_name }}</h2>
			<h1 @click="openBlogPost">{{ post.title }}</h1>
		</div>
		
		<div class="blog-post-thumbnail-img">
			<div class="blog-post-thumbnail-date">
				<span class="day">{{ post.created.day }}</span>
				<span class="month">{{ post.created.month }}</span>
			</div>
			
			<ImgLazy :url="imageSrc(post.thumb)" :alt="post.title" @click.native="openBlogPost" />
		</div>
		
		<div class="blog-post-thumbnail-text" v-html="post.description" />
	</div>
</template>

<script>
	import ImgLazy from "./ImgLazy";
	export default {
		name: "BlogPostThumb",
		components: {ImgLazy},
		data() {
			return {
			
			};
		},
		props: {
			post: {
				type: Object,
				required: true,
			}
		},
		methods: {
			openBlogPost() {
				this.$router.push({name: "blog-post", params: {id: this.post.id}});
			}
		}
	}
</script>

<style scoped lang="scss">
	.blog-post-thumbnail {
		width: 100%;
		float: left;
		padding-bottom: 60px;
		margin-bottom: 60px;
		border-bottom: 1px solid rgba($color-dark, 0.15);
		
		@include media-breakpoint-up(lg) {
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
				border-bottom: none;
			}
		}
		
		&-heading {
			width: 100%;
			
			h2 {
				color: $color-dark;
				font-size: $font-size-basic-sm;
				text-transform: uppercase;
				width: calc(100% - 90px);
				@include font-headings(300);
				margin: 0 0 15px 90px;
				text-align: center;
				display: block;
				cursor: default;
				width: calc(100% - 90px);
				
				@include media-breakpoint-down(md) {
					width: 100%;
					margin-left: 0;
					text-align: center;
					display: block;
				}
			}
			
			h1 {
				width: calc(100% - 90px);
				color: $color-dark;
				text-transform: uppercase;
				@include font-headings(300);
				margin: 0 0 20px 90px;
				padding: 0;
				font-size: $font-size-heading-2;
				cursor: pointer;
				text-align: center;
				
				&:hover {
					color: $color-primary;
					@include theme-transition();
				}
				
				@include media-breakpoint-down(md) {
					width: 100%;
					margin-left: 0;
					margin-bottom: 50px;
					text-align: center;
					display: block;
				}
			}
		}
		
		&-img {
			position: relative;
			width: 100%;
			
			.blog-post-thumbnail {
				&-date {
					z-index: 91;
					position: absolute;
					background: $color-dark;
					width: 50px;
					text-align: center;
					color: $white;
					top: 0;
					margin: 0;
					padding: 5px 0;
					transform: translateY(-50%);
					left: 20px;
					
					.day, .month {
						display: block;
						width: 100%;
					}
					
					.day {
						font-size: $font-size-heading-2;
					}
					
					.month {
						font-size: $font-size-basic-lg;
					}
					
					@include media-breakpoint-down(md) {
						width: 35px;
						
						.day {
							font-size: $font-size-heading-1;
						}
						
						.month {
							font-size: $font-size-basic-sm;
						}
					}
				}
			}
			
			img {
				width: 100%;
				z-index: 90;
				@include theme-transition();
				cursor: pointer;
				
				&:hover {
					filter: brightness(70%);
				}
			}
		}
		
		&-text {
			padding: 15px 0 0 0;
			width: 100%;
			text-align: justify;
		}
		
		&-actions {
			width: 100%;
			
			button {
				margin: 0;
				padding: 10px 20px;
				color: $color-dark;
				@include theme-transition();
				@include font-primary(500);
				font-size: $font-size-basic-lg;
				border: 1px solid $color-dark;
				border-radius: 0;
				float: right;
				
				&:hover {
					border: 1px solid $color-primary;
					background: $color-primary;
					color: $white;
				}
			}
			
			@include media-breakpoint-down(md) {
				text-align: center;
				
				button {
					margin-top: 30px;
					float: none;
				}
			}
		}
	}
</style>