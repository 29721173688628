<template>
	<section class="shopping-cart-step5">
		<div class="box-checkmark">
			<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
				<circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
				<path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
			</svg>
		</div>

		<h1>Objednávka bola dokončená.</h1>
		<p>Ďakujeme za Váš nákup. Potvrdenie o objednávke a detaily objednávky sme Vám zaslali na Vami zvolenú e-mailovú adresu.</p>
	</section>
</template>

<script>
	export default {
		name: "ShoppingCartStep5",
		mounted() {
			this.resetCart();
		},
		methods: {
			resetCart() {
				this.$store.dispatch('truncateCart', true);
			},
		},
	}
</script>

<style scoped lang="scss">
	.shopping-cart-step5 {
		.box-checkmark {
			margin-top: 100px;
			display: block;

			.checkmark {
				width: 56px;
				height: 56px;
				border-radius: 50%;
				display: block;
				stroke-width: 2;
				stroke: #fff;
				stroke-miterlimit: 10;
				margin: 15px auto;
				box-shadow: inset 0px 0px 0px $color-primary;
				animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;

				&-circle {
					stroke-dasharray: 166;
					stroke-dashoffset: 166;
					stroke-width: 2;
					stroke-miterlimit: 10;
					stroke: $color-primary;
					fill: none;
					animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
				}

				&-check {
					transform-origin: 50% 50%;
					stroke-dasharray: 48;
					stroke-dashoffset: 48;
					animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
				}

				@keyframes stroke {
					100% {
						stroke-dashoffset: 0;
					}
				}

				@keyframes scale {
					0%, 100% {
						transform: none;
					}
					50% {
						transform: scale3d(1.1, 1.1, 1);
					}
				}

				@keyframes fill {
					100% {
						box-shadow: inset 0px 0px 0px 30px $color-primary;
					}
				}
			}
		}

		h1 {
			@include font-primary();
			text-align: center;
			font-size: $font-size-heading-2;
		}

		p {
			margin: 0 auto;
			display: block;
			font-size: $font-size-basic-lg;
			max-width: 500px;
			text-align: center;
		}
	}
</style>
