<template>
	<main role="main" class="container">
		<div class="container-contents">
			<div class="wine-filters">
				<div class="wine-filters-filter">
					<h1>Aktívne filtre</h1>

					<ul>
						<li
							v-for="(item, id) in filter"
							:key="id"
							class="active"
						>{{ getFilterByCode(item) }} <span class="remove" @click="removeFilterItem(item)"><i class="fas fa-times"></i></span></li>
					</ul>
				</div>

				<div class="wine-filters-filter">
					<h1>Kategória</h1>

					<ul>
						<li
							class="filterable"
							v-for="(category, id) in filter_options.category"
							:key="id"
							@click="activateFilterItem(category.code)"
							:class="{'active': isFilterItemActive(category.code)}"
						>{{ category.title }}</li>
					</ul>
				</div>

				<div class="wine-filters-filter">
					<h1>Ročník</h1>

					<ul>
						<li
							class="filterable"
							v-for="(year, id) in filter_options.year"
							:key="id"
							@click="activateFilterItem(year.code)"
							:class="{'active': isFilterItemActive(year.code)}"
						>{{ year.title }}</li>
					</ul>
				</div>

				<div class="wine-filters-filter">
					<h1>Cukornatosť</h1>

					<ul>
						<li
							class="filterable"
							v-for="(sugar, id) in filter_options.sugar"
							:key="id"
							@click="activateFilterItem(sugar.code)"
							:class="{'active': isFilterItemActive(sugar.code)}"
						>{{ sugar.title }}</li>
					</ul>
				</div>
			</div>

			<div class="wine-products">
				<div class="alert alert-warning mb-5">
					Vína je možné objednať len ako kartón, teda po 6ks v balení.
				</div>

				<template v-for="category in categories">
					<ProductCard v-for="product in category.products" :key="product.id" :product="product"/>
				</template>
			</div>
		</div>
	</main>
</template>

<script>
	import ProductCard from "../components/ProductCard";
	import api from "../services/api";
	import {mapGetters} from "vuex";

	export default {
		name: "Products",
		components: {
			ProductCard
		},
		data() {
			return {
				filter: [],
				filter_options: {
					category: [],
					year: [],
					sugar: [],
					all: [],
				},
				categories: null,
			};
		},
		watch: {
			'filter': function () {
				this.reloadProducts();
			},
		},
		computed: {
			...mapGetters([
				'getProducts', 'getProductsCount', 'getProductFilters', 'getProductActiveFilters', 'getFilterByCode',
			]),
		},
		mounted() {
			this.loadProducts();
			this.filter = this.getProductActiveFilters;
		},
		methods: {
			loadProducts() {
				if(this.getProductsCount > 0) {
					this.categories = this.getProducts;
					this.filter_options = this.getProductFilters;
				} else {
					this.reloadProducts();
				}
			},
			reloadProducts() {
				//Load products:
				let _that = this;
				api.getProducts(this.filter).then(res => {
					//Store products:
					_that.categories = res.data.categories;
					_that.filter_options = res.data.filters;
					_that.$store.commit('setProducts', res.data.categories);
					_that.$store.commit('setProductFilters', res.data.filters);
				});

				//Store filters:
				this.$store.commit('setProductActiveFilters', this.filter);
			},
			activateFilterItem(item) {
				let index = this.filter.indexOf(item);

				if (index === -1) {
					this.filter.push(item);
				} else {
					this.filter.splice(index, 1);
				}
			},
			removeFilterItem(item) {
				let index = this.filter.indexOf(item);

				if (index !== -1) {
					this.filter.splice(index, 1);
				}
			},
			isFilterItemActive(item) {
				return this.filter.includes(item);
			}
		}
	}
</script>

<style scoped lang="scss">
	.container {
		&-contents {
			overflow: auto;
			min-height: inherit;
		}
	}

	$filter-padding: 40px;

	.wine-filters {
		float: left;
		width: 300px;
		margin-right: $filter-padding;
		overflow: auto;
		z-index: 80;
		//position: fixed;
		min-height: calc(100vh - 50px - #{$header-height-primary} - #{$header-height-secondary} - 400px);
		@include scrollbar-variant($body-bg, darken($body-bg, 10%), 5px, 3px, true);

		@include media-breakpoint-down(md) {
			width: 100%;
			float: none;
			position: relative;
			margin-right: 0;
		}

		&-filter {
			width: 100%;
			margin-bottom: 30px;

			h1 {
				@include font-headings(300);
				text-transform: uppercase;
				font-size: $font-size-heading-1;
			}

			ul {
				width: 100%;
				margin: 0;
				padding: 0;
				overflow: auto;
				list-style: none;

				li {
					float: left;
					margin-right: 15px;
					padding: 5px 15px;
					margin-bottom: 10px;
					border: 1px solid rgba($color-dark, 0.15);
					border-radius: 3px;
					@include theme-transition();
					@include font-primary(300);
					font-size: $font-size-basic;

					&:hover, &.active {
						border-color: $color-primary;
						background: $color-primary;
						color: $color-text-white;
					}

					&.filterable {
						cursor: pointer;
					}

					.remove {
						margin-left: 5px;
						font-size: $font-size-basic;
						cursor: pointer;
					}
				}
			}
		}
	}

	.wine-products {
		width: calc(100% - 300px - #{$filter-padding});
		float: right;

		@include media-breakpoint-down(md) {
			width: 100%;
			float: none;
		}

		.wine-category {
			h1, h2 {
				color: $color-primary;
				margin: 0 0 35px 0;
			}

			@include media-breakpoint-down(md) {
				h1 {
					text-align: center;

					&:before {
						content: "";
						display: block;
						background: rgba($color-light-1, 0.75);
						height: 1px;
						width: 100%;
						margin: 25px 0;
					}
				}
			}
		}
	}
</style>
