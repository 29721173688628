<template>
	<main role="main" class="container-fluid">
		<div class="slider">
			<div class="container">
				<IndexProductSliderItem v-for="(product, index) in slideshow" :key="index" :product="product" :index="index" />
			</div>
		</div>
		
		<div class="events" v-if="getEventsCount > 0">
			<h2>Najbližšie akcie</h2>
			
			<IndexEvent v-for="(event, index) in getEvents" :key="index" :event="event" :index="index" />
		</div>
		
		<div class="container about-us">
			<h2>O nás</h2>
			
			<div class="about-us-block" v-html="page.contents_short"></div>
			
			<router-link class="about-us-more" :to="page.url">Čítať viac</router-link>
		</div>
		
		<div class="products">
			<IndexProductCard v-for="(product, index) in favourite" :key="index" :product="product" :index="index" />
		</div>
	</main>
</template>

<script>
	import api from "../services/api";
	import {mapGetters} from "vuex";
	import IndexProductCard from "../components/IndexProductCard";
	import IndexProductSliderItem from "../components/IndexProductSliderItem";
	import IndexEvent from "../components/IndexEvent";

	export default {
		name: "Index",
		components: {IndexEvent, IndexProductSliderItem, IndexProductCard},
		data() {
			return {
				slideshow: null,
				favourite: null,
				page: {
					title: "",
					contents: "",
					url: "",
				},
			};
		},
		computed: {
			...mapGetters(['getProductsOnIndex', 'getProductsOnIndexCount', 'getEvents', 'getEventsCount'])
		},
		mounted() {
			this.loadProducts();
			this.loadPage();
			this.loadEvents();
		},
		methods: {
			loadProducts() {
				if(this.getProductsOnIndexCount > 0) {
					this.products = this.getProductsOnIndex;
				} else {
					this.reloadProducts();
				}
			},
			reloadProducts() {
				//Load products:
				let _that = this;
				api.getProductsOnIndex().then(res => {
					_that.slideshow = res.data.slideshow;
					_that.favourite = res.data.index;
					_that.$store.commit('setProductsOnIndex', res.data.products);
				});
			},
			loadPage() {
				let _that = this;
				api.getPage("/o-nas").then(res => {
					_that.page = res.data.page;
				});
			},
			loadEvents() {
				let _that = this;
				
				api.getEvents().then(res => {
					_that.$store.commit('setEvents', res.data.events);
				});
			},
		},
	}
</script>

<style scoped lang="scss">
	main {
		&.container-fluid {
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
		}
		
		.slider {
			$slider-height: 700px;
			$slider-height-mobile: 550px;
			
			width: 100%;
			height: calc(#{$slider-height} - #{$header-height-primary} - #{$header-height-secondary});
			background: darken($color-dark, 5%);
			padding: 0;
			margin: 0;
			
			.container {
				height: 100%;
				padding: 0 0 40px 0;
				margin: 0 auto;
			}
			
			&-item {
				$main-slider-center-item-width: 23%;
				
				height: 100%;
				width: $main-slider-center-item-width;
				padding-top: 7%;
				
				&:nth-child(3n+1), &:nth-child(3n+3) {
					width: (100% - $main-slider-center-item-width) / 2;
					padding-top: 18% - 5%;
					padding-bottom: 5%;
				}
				
				&:nth-child(3n+1) {
					text-align: right;
				}
				
				&:nth-child(3n+2) {
					text-align: center;
				}
				
				&:nth-child(3n+3) {
					text-align: left;
				}
			}
			
			@include media-breakpoint-down(md) {
				height: calc(#{$slider-height-mobile} - #{$header-height-primary} - #{$header-height-secondary});
				
				&-item {
					$main-slider-center-item-width: 43%;
					
					height: 100%;
					width: $main-slider-center-item-width;
					padding-top: 7%;
					
					&:nth-child(3n+1), &:nth-child(3n+3) {
						width: (100% - $main-slider-center-item-width) / 2;
						padding-top: 18% - 5%;
						padding-bottom: 5%;
					}
					
					&:nth-child(3n+1) {
						padding-left: 10%;
						text-align: left;
					}
					
					&:nth-child(3n+2) {
						text-align: center;
					}
					
					&:nth-child(3n+3) {
						padding-right: 10%;
						text-align: right;
					}
				}
			}
		}
		
		.products {
			padding: 16px 0 0;
		}
		
		.events {
			padding: 16px 0 0;
			
			h2 {
				text-align: center;
				position: relative;
				margin-top: 25px;
				margin-bottom: 35px;
				font-size: $font-size-heading-2;
				
				&:after {
					display: block;
					content: "";
					width: 90px;
					height: 2px;
					background: $color-primary;
					position: absolute;
					//top: -10px;
					top: 40px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
		
		.about-us {
			margin-top: 50px;
			overflow: auto;
			
			h2 {
				text-align: center;
				position: relative;
				margin-top: 25px;
				margin-bottom: 35px;
				font-size: $font-size-heading-2;
				
				&:after {
					display: block;
					content: "";
					width: 90px;
					height: 2px;
					background: $color-primary;
					position: absolute;
					//top: -10px;
					top: 40px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
			
			&-block {
			
			}
			
			&-more {
				margin-top: 20px;
				float: right;
				padding: 12px 35px;
				background: $color-dark;
				color: $color-light;
				text-transform: uppercase;
				font-weight: 500;
				font-size: $font-size-basic-sm;
				@include theme-transition();
				
				&:hover, &:visited, &:active {
					text-decoration: none;
					color: $color-light;
				}
				
				&:hover {
					background: $color-primary;
				}
			}
		}
	}
</style>