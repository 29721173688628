<template>
	<section class="shopping-cart-step4">
		<div class="overlay" :class="{'visible': processing}" v-if="processing">
			<i class="fas fa-sync fa-spin"></i>
		</div>
		
		<div class="shopping-cart">
			<div class="shopping-cart-step4-card">
				<div class="shopping-cart-step4-card-heading">
					<i class="fas fa-truck"></i> Osobné, dodacie a fakturačné údaje
				</div>

				<div class="shopping-cart-step4-card-inside">
					<div class="shopping-cart-step4-client row">
						<div class="col-xs-12 col-md-4">
							<h2>Osobné údaje</h2>
							{{ getCustomer.name }} {{ getCustomer.surname }}<br />
							{{ getCustomer.street }}<br />
							{{ getCustomer.postal }} {{ getCustomer.city }}<br /><br />
							{{ getCustomer.email }}<br />
							{{ getCustomer.phone }}
						</div>

						<div class="col-xs-12 col-md-4">
							<h2>Dodacie údaje</h2>
							Spôsob doručenia: {{ getDelivery.label }} ({{ $parent.formatPrice(getDelivery.price) }})<br/><br />

							<div v-if="getCustomer.delivery_address">
								{{ deliveryName }}<br />
								{{ getCustomer.delivery_address_street }}<br />
								{{ getCustomer.delivery_address_postal }} {{ getCustomer.delivery_address_city }}<br />
								{{ getCustomer.delivery_address_phone }}
							</div>
							<div v-else>
								Dodacie údaje sú zhodné s osobnými údajmi.
							</div>
						</div>

						<div class="col-xs-12 col-md-4">
							<h2>Fakturačné údaje</h2>
							Spôsob platby: {{ getPayment.label }} ({{ $parent.formatPrice(getPayment.price) }})<br/><br />

							<div v-if="getCustomer.company">
								{{ getCustomer.company_name }}<br />
								IČO: {{ getCustomer.company_ico }}<br />
								DIČ: {{ getCustomer.company_dic }}<br />
								IČ DPH: {{ getCustomer.company_icdph }}<br />
							</div>
							<div v-else>
								Fakturačné údaje sú zhodné s osobnými údajmi.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="shopping-cart-step4-card">
				<div class="shopping-cart-step4-card-heading">
					<i class="fas fa-shopping-cart"></i> Obsah košíka
				</div>

				<div class="shopping-cart-step4-card-inside">
					<ProductCartItem
							v-for="(item,index) in getCartProducts"
							:key="index"
							:item="item"
							:deletable="false"
							:editable="false"
							:last="index == (getCartProducts.length - 1)"
					/>
				</div>
			</div>
			
			<div class="shopping-cart-step4-card">
				<div class="shopping-cart-step4-card-heading">
					<i class="fas fa-percent"></i> Zľavy a akcie
				</div>
				
				<div class="shopping-cart-step4-card-inside">
					<div class="shopping-cart-step4-client row">
						<div class="col-6 discount-coupon">
							<label for="discount">Zľavový kupón</label>
							<div class="input-group" :class="{'invalid': !coupon_valid}">
								<input type="text" id="discount" class="form-control" placeholder="Zľavový kupón" v-model="discount" @blur="checkDiscount">
								<small class="form-text" v-if="!coupon_valid">Tento kupón je neplatný.</small>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="shopping-cart-step4-card">
				<div class="shopping-cart-step4-card-heading">
					<i class="fas fa-question-circle"></i> Ostatné
				</div>

				<div class="shopping-cart-step4-card-inside">
					<div class="shopping-cart-step4-client row">
						<div class="col-12">
							<label for="order_note">Poznámka k objednávke</label>
							<div class="input-group">
								<textarea class="form-control" id="order_note" v-model="note" placeholder="Zadajte Vašu poznámku k objednávke..."></textarea>
							</div>
						</div>

						<div class="col-12">
							<hr />
						</div>

						<div class="shopping-cart-step4-card-inside-checkbox">
							<input type="checkbox" class="form-check-input" id="store_conditions" v-model="store_conditions">
							<label for="store_conditions">Súhlasím s <router-link :to="'/obchodne-podmienky'">obchodnými podmienkami</router-link></label>
						</div>

						<div class="shopping-cart-step4-card-inside-checkbox">
							<input type="checkbox" class="form-check-input" id="GDPR" v-model="GDPR">
							<label for="GDPR">Súhlasím so <router-link :to="'/ochrana-osobnych-udajov'">spracovaním osobných údajov</router-link></label>
						</div>
						
						<div class="shopping-cart-step4-card-inside-checkbox" v-if="!(getCustomerId > 0)">
							<input type="checkbox" class="form-check-input" id="account" v-model="account">
							<label for="account">Chcem vytvoriť zákaznícke konto</label>
						</div>
						
						<div class="col-12" v-if="account">
							<div class="row">
								<div class="col-6">
									<label for="password">* Heslo</label>
									<div class="input-group" :class="{'invalid': !passwords_valid}">
										<input type="password" id="password" class="form-control" placeholder="* Heslo" v-model="password" autocomplete="new-password" />
										<small class="form-text" v-if="!passwords_valid">Zadané heslá nesúhlasia.</small>
									</div>
								</div>
								
								<div class="col-6">
									<label for="password2">* Heslo (znova)</label>
									<div class="input-group" :class="{'invalid': !passwords_valid}">
										<input type="password" id="password2" class="form-control" placeholder="* Heslo (znova)" v-model="password2" autocomplete="new-password" />
										<small class="form-text" v-if="!passwords_valid">Zadané heslá nesúhlasia.</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="shopping-cart-total">
				<div class="shopping-cart-total-inner">
					<h2>Medzisúčet: <span>{{ $parent.formatPrice(getSubtotal) }}</span></h2>
					<h2>Doprava: <span>{{ $parent.formatPrice(getDelivery ? getDelivery.price : 0) }}</span></h2>
					<h2>Platba: <span>{{ $parent.formatPrice(getPayment ? getPayment.price : 0) }}</span></h2>
					<h2 v-if="getDiscount">Zľava: <span>{{ formatPercent(getDiscount) }}</span></h2>
					<h2>DPH: <span>{{ $parent.formatPrice(getTotal(true) - (getTotal(true) / 1.2)) }}</span></h2>
					<h1>Celkom s DPH: <span>{{ $parent.formatPrice(getTotal(true)) }}</span></h1>
				</div>
			</div>

			<div class="shopping-cart-continue" :class="{'disabled': orderCanSend}">
				<button type="button" role="button" @click="sendOrder" :disabled="orderCanSend">Odoslať s povinnosťou platby</button>
			</div>
		</div>
	</section>
</template>

<script>
	import {mapGetters} from 'vuex';
	import ProductCartItem from "@/components/ProductCartItem";
	import api from "../../services/api"


	export default {
		name: "ShoppingCartStep4",
		components: {ProductCartItem},
		data() {
			return {
				note: "",
				discount: "",
				coupon_valid: true,
				GDPR: false,
				store_conditions: false,
				account: false,
				processing: false,
				discount_interval: false,
				password: "",
				password2: "",
				passwords_valid: true,
			}
		},
		computed: {
			...mapGetters([
				'getSubtotal', 'getCartProducts', 'getDelivery', 'getPayment', 'getTotal', 'getCustomer', 'getCustomerId', 'getDiscount',
			]),
			deliveryName() {
				//Is company or no?
				if(this.getCustomer.company) {
					return this.getCustomer.company_name;
				} else {
					return this.getCustomer.name + " " + this.getCustomer.surname;
				}
			},
			orderCanSend() {
				return !(this.GDPR && this.store_conditions && this.passwords_valid);
			}
		},
		watch: {
			discount: function () {
				if(this.discount_interval) {
					clearTimeout(this.discount_interval);
				}

				this.discount_interval = setTimeout(this.checkDiscount, 5000);
			},
			password: function () {
				this.validatePasswords();
			},
			password2: function () {
				this.validatePasswords();
			},
			account: function () {
				this.validatePasswords();
			},
		},
		methods: {
			validatePasswords() {
				if(this.account && (this.password.length < 3 || this.password2.length < 3)) {
					this.passwords_valid = false;
				} else {
					this.passwords_valid = this.password == this.password2;
				}
			},
			formatPercent(percent) {
				if(percent) {
					return String(Math.round(Math.abs(percent.discount - 1)  * 100)) + "%";
				}
				
				return "";
			},
			checkDiscount() {
				if(this.discount_interval) {
					clearTimeout(this.discount_interval);
				}
				
				let _that = this;
				
				api.validateDiscountCoupon(this.discount).then(res => {
					if(res.data.discount) {
						_that.coupon_valid = true;
						
						_that.$store.commit('setDiscountCoupon', {
							code: _that.discount,
							discount: res.data.discount,
						});
					} else {
						_that.coupon_valid = false;
						_that.$store.commit('setDiscountCoupon', false);
					}
				});
			},
			sendOrder() {
				//Set as processing:
				this.processing = true;
				
				//Create order:
				let _that = this;
				
				api.postOrder({
					products: this.getCartProducts,
					customer: this.getCustomer,
					id: this.getCustomerId,
					delivery: this.getDelivery.id,
					payment: this.getPayment.id,
					price: this.getSubtotal,
					note: this.note,
					account: this.account,
					discount: this.discount,
					password: this.password,
				}).then(res => {
					//Store order id:
					_that.$store.commit('setOrderId', res.data.order);
					
					//Create payment?
					if(res.data.card_pay) {
						api.createOrderPayment({id: res.data.order}).then(res => {
							location.href = res.data['_gopay'];
						});
					} else {
						_that.$parent.nextStep(4);
					}
				});
			},
		},
	}
</script>

<style scoped lang="scss">
	.shopping-cart-step4 {
		$step4-color  : rgba($color-text-dark, 0.15);
		$step4-color-1: rgba($color-text-dark, 0.25);
		$step4-color-2: rgba($color-text-dark, 0.5);
		$item-height: 60px;
		$input-size: 20px;
		
		.overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			display: block;
			z-index: 100;
			background: rgba(#000, 0.4);
			opacity: 0;
			text-align: center;
			line-height: 100vh;
			color: $color-primary;
			font-size: 90px;
			@include theme-transition();
			
			&.visible {
				opacity: 1;
				@include theme-transition();
			}
		}

		&-client {
			width: 100%;
			display: flex;
			margin-bottom: 20px;

			h2 {
				@include font-headings();
				font-size: $font-size-heading-1;
				color: $color-primary;
				padding: 20px 0 5px 0;
				margin: 0;
				font-weight: 300;
			}
			
			.discount-coupon {
				margin-top: 15px;
			}
			
			.input-group {
				&.invalid {
					color: $danger;
				}
				
				&.invalid input {
					border-color: $danger;
					
					&::placeholder {
						color: $danger;
					}
				}
				
				small {
					width: 100%;
					display: flex;
					margin: 5px 0 0 0;
					padding: 0;
				}
			}
		}

		&-card {
			width: 100%;
			display: block;
			margin: 0;
			padding: 0;
			overflow: auto;

			&-heading {
				@include font-primary();
				display: block;
				font-size: $font-size-heading-1;
				margin: 0;
				padding: 0 20px;
				line-height: 70px;
				background: $step4-color;

				i {
					margin-right: 15px;
					color: $color-primary;
				}
			}

			&-inside {
				padding: 0 15px;
				margin: 0;
				border: 1px solid $step4-color;

				&-checkbox {
					margin: 0;
					padding: 7.5px 15px;
					width: 100%;
					display: block;
					overflow: auto;
					cursor: pointer;

					label {
						display: inline-block;
						float: left;
						margin: 0 0 0 15px;
						padding: 0;
						user-select: none;
						cursor: pointer;
					}

					input[type="checkbox"] {
						width: 20px;
						height: 20px;
						cursor: pointer;
						margin: 0;
						border: 1px solid $step4-color-2;
						padding: 0;
						position: relative;
						float: left;
						display: inline-block;


						&:focus {
							outline: none;
						}

						&:checked {
							border-color: $color-primary;
							/*background: rgba($color-primary, 0.1);*/

							&:after {
								content: "";
								position: absolute;
								top: 1px;
								left: 6px;
								width: 6px;
								height: 12px;
								display: block;
								transform: rotateZ(45deg);
								border-bottom: 1px solid $color-primary;
								border-right: 1px solid $color-primary;
							}
						}
					}
				}
			}

			@include media-breakpoint-down(md) {
				&-heading {
					font-size: $font-size-bigger;
					padding: 0 15px;
				}
			}
		}
		
		.shopping-cart {
			width: 100%;

			&-total {
				width: 100%;
				margin: 0;

				&-inner {
					float: right;
					display: block;
					overflow: visible;
					padding: 0 20px;
					margin: 30px 0 0;

					h1, h2 {
						@include font-primary();
						font-size: $font-size-bigger;
						color: $color-dark;
						display: block;
						width: 300px;
						text-align: left;

						span {
							@include font-primary(500);
							width: 150px;
							font-size: $font-size-heading-1;
							color: $color-primary;
							display: inline-block;
							text-align: right;
							float: right;
						}
					}

					h2 {
						font-size: $font-size-bigger - 3px;
						
						span {
							font-weight: 300;
							font-size: $font-size-heading-1 - 3px;
						}
					}

					@include media-breakpoint-down(md) {
						width: 100%;
						padding: 0 10px;
						float: none;

						h1, h2 {
							width: 100%;
							text-align: left;
							font-size: $font-size-basic-lg;

							span {
								width: auto;
								font-size: $font-size-bigger;
							}
						}
					}
				}
			}

			&-continue {
				width: 100%;
				overflow: auto;

				button, a {
					background: $color-primary;
					color: $color-text-white;
					padding: 12.5px 30px;
					@include font-primary(400);
					font-size: $font-size-bigger;
					text-transform: uppercase;
					float: right;
					border: none;
					outline: none;
					text-decoration: none;
					@include theme-transition();

					&:hover {
						background: $color-primary-hover;
					}
				}

				&.disabled {
					button, a {
						cursor: not-allowed;
						background: $color-light-2;
					}
				}
			}
		}
	}
</style>