<template>
	<main role="main" class="container">
		<section class="recovery">
			<h1>Obnova hesla</h1>
			<h2>Zadajte svoje nové prihlasovacie údaje.</h2>
			
			<form @submit.prevent="recovery()" class="recovery-customer-form" enctype="multipart/form-data" method="post">
				<div class="form-item" :class="{'invalid': isInvalid('password')}">
					<input type="password" name="login_password" placeholder="* Heslo" v-model="password">
					<small class="form-text">Táto položka je vyžadovaná.</small>
				</div>
				
				<div class="form-item" :class="{'invalid': isInvalid('password2')}">
					<input type="password" name="login_password2" placeholder="* Heslo (znova)" v-model="password2">
					<small class="form-text">Heslá sa musia zhodovať.</small>
				</div>
				
				<div class="form-item">
					<button type="submit" role="button" @click.prevent="recovery()">Zmeniť heslo</button>
				</div>
			</form>
		</section>
	</main>
</template>

<script>
	import api from "@/services/api";
	import { required, sameAs } from 'vuelidate/lib/validators'
	
	export default {
		name: "PasswordRecovery",
		data() {
			return {
				password: "",
				password2: "",
			};
		},
		validations: {
			password: {
				required,
			},
			password2: {
				sameAs: sameAs('password'),
			},
		},
		methods: {
			isInvalid(field) {
				return this.$v[field] && this.$v[field].$error;
			},
			recovery() {
				//Validate form:
				this.$v.$touch();
				
				//Is valid?
				if(!this.$v.$invalid) {
					//Create data object:
					let data = {
						params: this.$route.query,
						password: this.password,
						password2: this.password2,
					};
					
					//Request:
					let _that = this;
					api.resetCustomerPassword(data).then(res => {
						if(res.data.ok) {
							_that.$swal.fire("Uložené!", "Vaše heslo bolo úspešne zmenené. Pokračujte prihlásením.", "success").then(res => {
								if(res.isConfirmed) {
									_that.$router.push('/prihlasenie').catch(() => {});
								}
							});
						} else {
							_that.$swal.fire("Chyba!", "Nastala chyba na strane servera!", "warning");
						}
					});
				}
			}
		},
	}
</script>

<style scoped lang="scss">
.recovery {
	width: 100%;
	text-align: center;
	
	.form-item {
		margin: 0 auto 20px auto;
		width: 40%;
		display: block;
		
		input {
			@include font-primary();
			font-size: $font-size-basic;
			padding: 10px 12px;
			width: 100%;
			border: 1px solid $color-dark;
			outline: none;
			@include theme-transition();
			
			&:focus {
				border-color: $color-primary;
			}
		}
		
		button, a {
			background: $color-primary;
			color: $color-text-white;
			padding: 12.5px 30px;
			@include font-primary();
			font-size: $font-size-basic;
			text-transform: uppercase;
			border: none;
			outline: none;
			text-decoration: none;
			@include theme-transition();
			
			&:hover {
				background: $color-primary-hover;
			}
		}
	}
	
	h1, h2 {
		margin: 0;
		padding: 0;
	}
	
	h1 {
		font-size: $font-size-heading-2;
		margin-bottom: 10px;
	}
	
	h2 {
		font-size: $font-size-basic-lg;
		color: lighten($color-text-dark, 1%);
		margin-bottom: 25px;
	}
	
	&-customer {
		overflow: visible;
		position: relative;
	}
	
	&-divider {
		width: 2px;
		padding: 0;
		background: $color-primary;
		flex: unset !important;
		align-self: stretch;
	}
	
	.form-item {
		small.form-text {
			color: $color-error;
			width: 100%;
			display: none;
		}
		
		&.invalid small.form-text {
			display: block;
		}
	}
	
	@include media-breakpoint-down(md) {
		.form-item {
			width: 100%;
		}
	}
}
</style>