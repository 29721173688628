<template>
	<div class="blog-post-last" @click="openBlogPost">
		<div class="blog-post-last-img">
			<ImgLazy :url="imageSrc(post.thumb)" :alt="post.title" />
		</div>
		
		<div class="blog-post-last-title" :class="{'light': footer}">
			<h1>{{ post.title }}</h1>
			<h2>{{ post.created.full }}</h2>
		</div>
	</div>
</template>

<script>
	import ImgLazy from "./ImgLazy";
	
	export default {
		name: "BlogPostLast",
		components: {ImgLazy},
		props: {
			post: {
				type: Object,
				required: true,
			},
			footer: {
				type: Boolean,
				required: false,
				default: false,
			}
		},
		methods: {
			openBlogPost() {
				this.$router.push({name: "blog-post", params: {id: this.post.id}});
			}
		}
	}
</script>

<style scoped lang="scss">
	.blog-post-last {
		width: 100%;
		overflow: auto;
		margin-bottom: 25px;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		
		$blog-post-last-img-size: 90px;
		$space-between: 15px;
		
		&-img {
			width: $blog-post-last-img-size;
			margin-right: $space-between;
			float: left;
			
			img {
				width: $blog-post-last-img-size;
				height: $blog-post-last-img-size;
				border-radius: 50%;
				object-fit: cover;
			}
		}
		
		&-title {
			width: calc(100% - #{$blog-post-last-img-size} - #{$space-between});
			float: left;
			
			h1, h2 {
				@include font-primary(300);
				font-size: $font-size-basic-lg;
				margin: 0;
				padding: 0;
				color: $color-dark;
			}
			
			h2 {
				margin-top: 5px;
				color: $color-primary;
			}
			
			&.light {
				h1 {
					color: $color-text-white;
				}
			}
		}
	}
</style>