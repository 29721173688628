<template>
	<section class="shopping-cart-step3">
		<div class="shopping-cart-step3-card">
			<div class="shopping-cart-step3-card-heading">
				<i class="fas fa-home"></i> Osobné údaje
			</div>

			<div class="shopping-cart-step3-card-inside">
				<div class="shopping-cart-step3-row" v-if="getCustomerId == 0">
					<div class="shopping-cart-step3-row-item info">
						Máte už konto? <strong @click="goToLogin">Prihláste sa</strong> pre rýchlejšie dokončenie nákupu.
					</div>
					<hr />
				</div>

				<div class="shopping-cart-step3-row">
					<div class="shopping-cart-step3-row-item">
						<label for="pd_name">Meno</label>
						<div class="input-group" :class="{'invalid': isInvalid('name')}">
							<input type="text" id="pd_name" class="form-control" placeholder="* Meno" v-model="clientData.name">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>

					<div class="shopping-cart-step3-row-item">
						<label for="pd_surname">Priezvisko</label>
						<div class="input-group" :class="{'invalid': isInvalid('surname')}">
							<input type="text" id="pd_surname" class="form-control" placeholder="* Priezvisko" v-model="clientData.surname">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>
				</div>
				
				<div class="shopping-cart-step3-row">
					<div class="shopping-cart-step3-row-item">
						<label for="pd_email">E-mail</label>
						<div class="input-group" :class="{'invalid': isInvalid('email')}">
							<input type="email" id="pd_email" class="form-control" placeholder="* E-mail" v-model="clientData.email">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>

					<div class="shopping-cart-step3-row-item">
						<label for="pd_phone">Telefón</label>
						<div class="input-group" :class="{'invalid': isInvalid('phone')}">
							<input type="text" id="pd_phone" class="form-control" placeholder="* Telefón" v-model="clientData.phone">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="shopping-cart-step3-card">
			<div class="shopping-cart-step3-card-heading">
				<i class="fas fa-truck"></i> Dodacie a fakturačné údaje
			</div>

			<div class="shopping-cart-step3-card-inside">
				<!-- Is company? -->
				<div class="shopping-cart-step3-row">
					<div class="shopping-cart-step3-card-inside-checkbox">
						<input type="checkbox" class="form-check-input" id="is_company" v-model="clientData.company">
						<label class="" for="is_company">Nakupujem na firmu</label>
					</div>
				</div>

				<!-- Company data -->
				<div class="shopping-cart-step3-row" :class="{'hidden': !clientData.company}">
					<div class="shopping-cart-step3-row-item">
						<label for="company_name">Obchodné meno</label>
						<div class="input-group" :class="{'invalid': isInvalid('company_name')}">
							<input type="text" id="company_name" class="form-control" placeholder="* Obchodné meno" v-model="clientData.company_name">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>

					<div class="shopping-cart-step3-row-item">
						<label for="company_ico">IČO</label>
						<div class="input-group" :class="{'invalid': isInvalid('company_ico')}">
							<input type="text" id="company_ico" class="form-control" placeholder="* IČO" v-model="clientData.company_ico">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>
				</div>
				
				<div class="shopping-cart-step3-row" :class="{'hidden': !clientData.company}">
					<div class="shopping-cart-step3-row-item">
						<label for="company_dic">DIČ</label>
						<div class="input-group" :class="{'invalid': isInvalid('company_dic')}">
							<input type="text" id="company_dic" class="form-control" placeholder="* DIČ" v-model="clientData.company_dic">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>

					<div class="shopping-cart-step3-row-item">
						<label for="company_icdph">IČ DPH</label>
						<div class="input-group">
							<input type="text" id="company_icdph" class="form-control" placeholder="IČ DPH" v-model="clientData.company_icdph">
						</div>
					</div>
				</div>
				
				<div class="shopping-cart-step3-row" :class="{'hidden': !clientData.company}">
					<hr />
				</div>

				<!-- Address-->
				<div class="shopping-cart-step3-row">
					<div class="shopping-cart-step3-row-item">
						<label for="street">Ulica a číslo</label>
						<div class="input-group" :class="{'invalid': isInvalid('street')}">
							<input type="text" id="street" class="form-control" placeholder="* Ulica a číslo" v-model="clientData.street">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>

					<div class="shopping-cart-step3-row-item">
						<label for="city">Mesto / obec</label>
						<div class="input-group" :class="{'invalid': isInvalid('city')}">
							<input type="text" id="city" class="form-control" placeholder="* Mesto / obec" v-model="clientData.city">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>
				</div>
				
				<div class="shopping-cart-step3-row">
					<div class="shopping-cart-step3-row-item">
						<label for="postal">PSČ</label>
						<div class="input-group" :class="{'invalid': isInvalid('postal')}">
							<input type="text" id="postal" class="form-control" placeholder="* PSČ" v-model="clientData.postal">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>
				</div>

				<!-- Alternative delivery address? -->
				<div class="shopping-cart-step3-row">
					<div class="shopping-cart-step3-card-inside-checkbox">
						<input type="checkbox" class="form-check-input" id="alt_delivery" v-model="clientData.delivery_address">
						<label class="" for="alt_delivery">Tovar chcem doručiť na inú adresu</label>
					</div>
				</div>

				<!-- Alternative address -->
				<div class="shopping-cart-step3-row" :class="{'hidden': !clientData.delivery_address}">
					<hr />
				</div>
				
				<div class="shopping-cart-step3-row" :class="{'hidden': !clientData.delivery_address}">
					<div class="shopping-cart-step3-row-item">
						<label for="alt_street">Ulica a číslo</label>
						<div class="input-group" :class="{'invalid': isInvalid('delivery_address_street')}">
							<input type="text" id="alt_street" class="form-control" placeholder="* Ulica a číslo" v-model="clientData.delivery_address_street">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>

					<div class="shopping-cart-step3-row-item">
						<label for="alt_city">Mesto / obec</label>
						<div class="input-group" :class="{'invalid': isInvalid('delivery_address_city')}">
							<input type="text" id="alt_city" class="form-control" placeholder="* Mesto / obec" v-model="clientData.delivery_address_city">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>
				</div>
				
				<div class="shopping-cart-step3-row" :class="{'hidden': !clientData.delivery_address}">
					<div class="shopping-cart-step3-row-item">
						<label for="alt_postal">PSČ</label>
						<div class="input-group" :class="{'invalid': isInvalid('delivery_address_postal')}">
							<input type="text" id="alt_postal" class="form-control" placeholder="* PSČ" v-model="clientData.delivery_address_postal">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>

					<div class="shopping-cart-step3-row-item">
						<label for="alt_phone">Telefón</label>
						<div class="input-group" :class="{'invalid': isInvalid('delivery_address_phone')}">
							<input type="text" id="alt_phone" class="form-control" placeholder="Telefón" v-model="clientData.delivery_address_phone">
							<small class="form-text">Táto položka je vyžadovaná.</small>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="shopping-cart-step3-total">
			<div class="shopping-cart-step3-total-inner">
				<h2>Doprava: <span>{{ $parent.formatPrice(getDelivery ? getDelivery.price : 0) }}</span></h2>
				<h2>Platba: <span>{{ $parent.formatPrice(getPayment ? getPayment.price : 0) }}</span></h2>
				<h1>Cena celkom: <span>{{ $parent.formatPrice(getTotal(false)) }}</span></h1>
			</div>
		</div>

		<div class="shopping-cart-step3-continue">
			<button type="button" role="button" @click="proceedToNextStep">Pokračovať</button>
		</div>
	</section>
</template>

<script>
	import {mapGetters} from 'vuex';
	import { required, minLength } from 'vuelidate/lib/validators'

	export default {
		name: "ShoppingCartStep3",
		data() {
			return {
				clientData: {
					id: 0,
					name: "",
					surname: "",
					email: "",
					phone: "",
					street: "",
					city: "",
					postal: "",
					company: "",
					company_name: "",
					company_ico: "",
					company_dic: "",
					company_icdph: "",
					delivery_address: "",
					delivery_address_street: "",
					delivery_address_city: "",
					delivery_address_postal: "",
					delivery_address_phone: "",
				},
			};
		},
		validations() {
			//Delivery address validations:
			let delivery_address = {
				delivery_address_street: {
					required,
					minLength: minLength(2),
				},
				delivery_address_city: {
					required,
					minLength: minLength(2),
				},
				delivery_address_postal: {
					required,
					minLength: minLength(4),
				},
				delivery_address_phone: {
					required,
					minLength: minLength(3),
				},
			};
			
			//Company validations:
			let company = {
				company_name: {
					required,
					minLength: minLength(2),
				},
				company_ico: {
					required,
					minLength: minLength(5),
				},
				company_dic: {
					required,
					minLength: minLength(5),
				},
			}
			
			//Company?
			if(!this.clientData.company) {
				company = false;
			}
			
			//Delivery address?
			if(!this.clientData.delivery_address) {
				delivery_address = false;
			}
			
			//Return validations object:
			return {
				clientData: {
					name: {
						required,
						minLength: minLength(3),
					},
					surname: {
						required,
						minLength: minLength(3),
					},
					email: {
						required,
						minLength: minLength(3),
					},
					phone: {
						required,
						minLength: minLength(3),
					},
					street: {
						required,
						minLength: minLength(2),
					},
					city: {
						required,
						minLength: minLength(2),
					},
					postal: {
						required,
						minLength: minLength(4),
					},
					...company,
					...delivery_address,
				}
			};
			
		},
		computed: {
			...mapGetters([
				'getTotal', 'getPayment', 'getDelivery', 'getCustomer', 'getCustomerId',
			]),
		},
		watch: {
			clientData: {
				handler: function () {
					this.$store.commit('setCustomer', this.clientData);
				},
				deep: true,
			}
		},
		mounted() {
			this.loadData();
		},
		methods: {
			isInvalid(field) {
				return this.$v.clientData[field] && this.$v.clientData[field].$error;
			},
			goToLogin() {
				this.$store.commit('setRedirAfterLogin', this.$route.path);
				this.$router.push('/prihlasenie').catch(() => {});
			},
			loadData() {
				this.clientData = this.getCustomer;
			},
			proceedToNextStep() {
				//Validate:
				this.$v.$touch();
				
				//Is valid?
				if(!this.$v.$invalid) {
					//Store data:
					this.$store.commit('setCustomer', this.clientData);
					
					//Navigate:
					this.$parent.nextStep(3);
				}
			},
		},
	}
</script>

<style scoped lang="scss">
	.shopping-cart {
		&-step3 {
			width: 100%;

			$step3-color  : rgba($color-text-dark, 0.15);
			$step3-color-1: rgba($color-text-dark, 0.25);
			$step3-color-2: rgba($color-text-dark, 0.5);
			$item-height: 60px;
			$input-size: 20px;

			&-row {
				margin: 0;
				padding: 7.5px 0;
				overflow: auto;
				@include theme-transition();
				transform-origin: top;
				transform: scaleY(1);
				opacity: 1;
				visibility: visible;
				display: flex;
				
				small.form-text {
					color: $color-error;
					width: 100%;
					display: none;
				}
				
				.input-group.invalid small.form-text {
					display: block;
				}

				hr {
					width: calc(100% - 30px);
					height: 1px;
					margin: 15px auto;
					display: block;
					background: $step3-color-1;
					border: none;
					outline: none;
					float: left;
				}

				&.hidden {
					transform: scaleY(0);
					opacity: 0;
					height: 0;
				}

				&-item {
					padding: 7.5px 15px;
					margin: 0;
					display: inline-block;
					float: left;
					width: 50%;
					
					small {
						width: 100%;
						display: flex;
						margin: 5px 0 0 0;
						padding: 0;
					}

					&-half {
						padding: 7.5px 15px;
						margin: 0;
						display: inline-block;
						float: left;
						width: 25%;
					}

					&.info {
						font-weight: 400;
						font-size: $font-size-bigger;
						width: 100%;

						strong {
							cursor: pointer;
							font-weight: 400;
							color: $color-primary;
							@include theme-transition();

							&:hover {
								color: $color-primary-hover;
							}
						}
					}

					@include media-breakpoint-down(md) {
						width: 100%;

						&-half {
							width: 50%;
						}
					}
				}
				
				@include media-breakpoint-down(md) {
					display: block;
				}
			}

			&-card {
				width: 100%;
				display: block;
				margin: 0;
				padding: 0;
				overflow: auto;

				&-heading {
					@include font-primary();
					display: block;
					font-size: $font-size-heading-1;
					margin: 0;
					padding: 0 20px;
					line-height: 70px;
					background: $step3-color;

					i {
						margin-right: 15px;
						color: $color-primary;
					}
				}

				&-inside {
					padding: 0 15px;
					margin: 0;
					border: 1px solid $step3-color;

					&-checkbox {
						margin: 0;
						padding: 7.5px 15px;
						width: 100%;
						display: block;
						overflow: auto;
						cursor: pointer;

						label {
							display: inline-block;
							float: left;
							margin: 0 0 0 15px;
							padding: 0;
							user-select: none;
							cursor: pointer;
						}

						input[type="checkbox"] {
							width: 20px;
							height: 20px;
							cursor: pointer;
							margin: 0;
							border: 1px solid $step3-color-2;
							padding: 0;
							position: relative;
							float: left;
							display: inline-block;


							&:focus {
								outline: none;
							}

							&:checked {
								border-color: $color-primary;
								/*background: rgba($color-primary, 0.1);*/

								&:after {
									content: "";
									position: absolute;
									top: 1px;
									left: 6px;
									width: 6px;
									height: 12px;
									display: block;
									transform: rotateZ(45deg);
									border-bottom: 1px solid $color-primary;
									border-right: 1px solid $color-primary;
								}
							}
						}
					}
				}

				@include media-breakpoint-down(md) {
					&-heading {
						font-size: $font-size-bigger;
						padding: 0 15px;
					}
				}
			}

			&-total {
				width: 100%;
				margin: 0;

				&-inner {
					float: right;
					display: block;
					overflow: visible;
					padding: 0 20px;
					margin: 30px 0 0;

					h1, h2 {
						@include font-primary();
						font-size: $font-size-bigger;
						color: $color-dark;
						display: block;
						width: 300px;
						text-align: left;

						span {
							@include font-primary(500);
							width: 150px;
							font-size: $font-size-heading-1;
							color: $color-primary;
							display: inline-block;
							text-align: right;
							float: right;
						}
					}

					h2 {
						span {
							font-weight: 400;
							font-size: $font-size-heading-1;
						}
					}

					@include media-breakpoint-down(md) {
						width: 100%;
						padding: 0 10px;
						float: none;

						h1, h2 {
							width: 100%;
							text-align: left;
							font-size: $font-size-basic-lg;

							span {
								width: auto;
								font-size: $font-size-bigger;
							}
						}
					}
				}
			}

			&-continue {
				width: 100%;
				overflow: auto;

				button, a {
					background: $color-primary;
					color: $color-text-white;
					padding: 12.5px 30px;
					@include font-primary(400);
					font-size: $font-size-bigger;
					text-transform: uppercase;
					float: right;
					border: none;
					outline: none;
					text-decoration: none;
					@include theme-transition();

					&:hover {
						background: $color-primary-hover;
					}
				}
			}
		}
	}
</style>