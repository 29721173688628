import Axios from "axios";
import store from "../store";

const axios = Axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_LOCAL_URL : process.env.VUE_APP_API_URL,
    params: {
        sessid: localStorage.sessid,
    }
});

axios.interceptors.response.use(
    response => {
        //Store response sessid:
        if(response && response.data && response.data.sessid) {
            localStorage.setItem('sessid', response.data.sessid);
        }

        //Request ended:
        store.dispatch('runningRequestEnded');

        //Continue:
        return response;
    }, err => {
        return err;
    }
);

axios.interceptors.request.use(config => {
    store.dispatch('newRequestStarted');

    return config;
})

function getPage(uri) {
    return axios.get("pages" + uri);
}

function getProducts(filter) {
    return axios.get("products", {
        headers: {
            'accept': 'application/json'
        },
        params: {
            filter: filter,
        },
    });
}

function getProductsOnIndex() {
    return axios.get("products/index", {
        headers: {
            'accept': 'application/json'
        },
    });
}

function getEvents() {
    return axios.get("events", {
        headers: {
            'accept': 'application/json'
        },
    });
}

function getCustomer() {
    return axios.get("customer", {
        headers: {
            'accept': 'application/json'
        },
        params: {

        },
    });
}

function postCustomer(data = {}) {
    return axios.post("customer", data);
}

function patchCustomer(id, data = {}) {
    return axios.patch("customer/edit/" + id, data);
}

function resetCustomerPassword(data = {}) {
    return axios.post("customer/reset", data);
}

function getShops() {
    return axios.get("shops", {
        headers: {
            'accept': 'application/json'
        },
        params: {

        },
    });
}

function getDeliveryOptions() {
    return axios.get("store/settings/delivery", {
        headers: {
            'accept': 'application/json'
        },
        params: {

        },
    });
}

function getPaymentOptions() {
    return axios.get("store/settings/payment", {
        headers: {
            'accept': 'application/json'
        },
        params: {

        },
    });
}

function postOrder(data = {}) {
    return axios.post("store/orders", data);
}

function createOrderPayment(data = {}) {
    return axios.post("payment/order/pay", data);
}

function getOrders() {
    return axios.get("customer/orders/1", {
        headers: {
            'accept': 'application/json'
        },
        params: {

        },
    });
}

function getBlog(filter) {
    return axios.get("blog", {
        headers: {
            'accept': 'application/json'
        },
        params: {
            ...filter
        }
    });
}

function removeCustomerAccount(customerId) {
    return axios.delete("customer/" + customerId, {
        headers: {
            'accept': 'application/json'
        }
    });
}

function validateDiscountCoupon(couponCode) {
    return axios.get("validate-discount", {
        headers: {
            'accept': 'application/json'
        },
        params: {
            discount: couponCode,
        }
    });
}

const services = {
    getPage,
    getProducts,
    getProductsOnIndex,
    getCustomer,
    postCustomer,
    patchCustomer,
    getShops,
    getDeliveryOptions,
    getPaymentOptions,
    postOrder,
    createOrderPayment,
    resetCustomerPassword,
    getOrders,
    getBlog,
    removeCustomerAccount,
    getEvents,
    validateDiscountCoupon,
};


export default services;
