<template>
	<main role="main" class="container">
		<section class="wine-product">
			<div class="wine-product-row">
				<div class="wine-product-gallery">
					<carousel
							:per-page="1"
							:loop="true"
							:autoplay="true"
							:autoplay-timeout="1500"
							:pagination-active-color="'#F7D163'"
							:pagination-color="'#969696'"
							:pagination-enabled="true">
						<slide :key="0">
							<img :src="imageSrc(product.img_thumb)" class="lazy" :data-src="imageSrc(product.img_thumb)" :alt="product.name" />
						</slide>
						<slide v-for="(img, index) in product.img_gallery" :key="index + 1">
							<img :src="imageSrc(img)" class="lazy" :data-src="imageSrc(img)" :alt="product.name" />
						</slide>
					</carousel>
				</div>

				<div class="wine-product-details">
					<h1>{{ product.name }}</h1>
					<h3>{{ category.name }}</h3>

					<div class="wine-product-details-desc">
						<p class="wine-product-details-desc-paragraph" v-html="product.description_short" />

						<div class="wine-price">
							{{ formatPrice(product.price) }}
						</div>

						<div class="wine-stock">
							<label>Dostupnosť:</label>
							<span>skladom</span>
						</div>

						<div class="wine-buy">
							<input type="number" min="1" value="1" v-model="amount" />
							<button type="button" class="btn" @click="addToCart">Pridať do košíka</button>
						</div>
					</div>
				</div>
			</div>

			<div class="wine-product-table-paragraph" v-html="product.description" />

			<div class="wine-product-table-extend">
				<div @click="toggleDetails">
					<h2>Detaily vína</h2>

					<div class="wine-product-table-extend-arrow" :class="{'rotated': details_extended}">
						<i class="fas fa-angle-down"></i>
					</div>
				</div>
			</div>

			<div class="wine-product-table" :class="{'extended': details_extended}">
				<table class="table">
					<tbody>
					<tr>
						<td>Ročník:</td>
						<td>{{ product.year }}</td>
					</tr>
					<tr>
						<td>Cukornatosť:</td>
						<td>{{ product.sugar_divide }}</td>
					</tr>
					<tr>
						<td>Zvyškový cukor vo víne:</td>
						<td>{{ product.sugar_in }} g/l</td>
					</tr>
					<tr>
						<td>Vinohradnícka oblasť:</td>
						<td>{{ product.vineyeard_area }}</td>
					</tr>
					<tr>
						<td>Prívlastok:</td>
						<td>{{ product.sugar_grapes_text }}</td>
					</tr>
					<tr>
						<td>Cukornatosť hrozna:</td>
						<td>{{ product.sugar_grapes }}°NM</td>
					</tr>
					<tr>
						<td>Obsah kyselín:</td>
						<td>{{ product.analytics_kys }} g/l</td>
					</tr>
					<tr>
						<td>Obsah alkoholu:</td>
						<td>{{ product.analytics_alc }}%</td>
					</tr>
					<tr>
						<td>Teplota skladovania:</td>
						<td>{{ storageTemp }}</td>
					</tr>
					<tr>
						<td>Teplota podávania:</td>
						<td>{{ product.table_temp }}°C</td>
					</tr>
					
					<tr>
						<td>CO<sub>2</sub> vo fľaši:</td>
						<td>{{ product.co_pressure }}</td>
					</tr>
					</tbody>
				</table>
			</div>
		</section>
	</main>
</template>

<script>
	import api from "../services/api";
	import {mapGetters} from "vuex";

	export default {
		name: "ProductDetail",
		computed: {
			storageTemp: function () {
				return [
					this.product.storage_temp_min,
					this.product.storage_temp_max
				].join("°C - ") + "°C";
			},
			...mapGetters([
				'getProductsCount', 'getProductById', 'getCategoryById',
			]),
		},
		data() {
			return {
				product: {
					name: "",
					price: 0,
					category_id: 0,
					stock: {
						amount: 0,
						limited: true,
					}
				},
				category: {
					name: "",
				},
				amount: 1,
				details_extended: false,
			};
		},
		mounted() {
			this.loadProduct();
		},
		methods: {
			toggleDetails() {
				this.details_extended = !this.details_extended;
			},
			loadProduct() {
				if(this.getProductsCount > 0) {
					this.product = this.getProductById(this.$route.params.id);
					this.category = this.getCategoryById(this.product.category_id);
					this.$store.commit("setTitle", this.product.name);
				} else {
					this.reloadProducts();
				}
			},
			reloadProducts() {
				let _that = this;
				api.getProducts(this.filter).then(res => {
					//Store products:
					_that.$store.commit('setProducts', res.data.categories);
					_that.product = _that.getProductById(_that.$route.params.id);
					_that.category = _that.getCategoryById(_that.product.category_id);
				});
			},
			formatPrice: function (price) {
				//Free or not?
				if(price > 0) {
					//Two decimals:
					price = (Math.round(price * 100) / 100).toFixed(2) + "€";
				} else {
					price = "zdarma";
				}

				//Return formatted price:
				return price;
			},
			addToCart() {
				this.$store.dispatch('addCartProduct', {
					amount: this.amount,
					product: this.product,
				});
				this.$notify({
					group: 'shopping-cart',
					title: "Položka " + this.product.name + " bola pridaná do košíka",
					text: "Bolo pridaných " + this.amount + "ks do košíka.",
					duration: 5000,
				});
			},
		},
	}
</script>

<style lang="scss">
	.VueCarousel-inner {
		height: 100% !important;
	}
</style>

<style scoped lang="scss">
	.wine-product {
		display: block;
		overflow: visible;

		&-row {
			width: 100%;
			display: block;
			overflow: auto;
		}

		&-gallery {
			width: 40%;
			float: left;
			text-align: center;
			margin-right: 10%;
			height: 650px;

			img {
				height: 100%;
				max-width: 100%;
			}
			
			.VueCarousel {
				height: 100%;

				img {
					max-height: 580px;
				}
			}
			
			@include media-breakpoint-down(md) {
				height: 450px;
				width: 100%;
				margin-bottom: 30px;

				.VueCarousel {
					height: 100%;

					img {
						height: 380px;
					}
				}
			}
		}

		&-details {
			display: block;
			margin-top: 60px;
			margin-bottom: 50px;
			overflow: hidden;
			width: 50%;

			@include media-breakpoint-down(md) {
				width: 100%;
				margin-top: 0;
				margin-bottom: 0px;
			}

			&-desc {
				margin: 0 5% 0 0;
				font-size: $font-size-basic-lg;
				width: 100%;

				.wine-price {
					margin: 25px 0 8px 0;
					font-size: $font-size-heading-1;
					font-weight: 700;
					color: $color-primary;
				}

				.wine-stock {
					margin: 8px 0 20px 0;
					overflow: hidden;

					label {
						display: inline-block;
						float: left;
					}

					span {
						margin-left: 16px;
						display: inline-block;
						float: left;
					}
				}

				p.wine-product-details-desc-paragraph {
					text-align: justify;
				}

				@include wine-buy($buy-vignette-height + 10px, $buy-vignette-height, 15px, 18px);
			}
		}

		&-subtitle {
			width: 100%;
			text-align: center;
			height: 51px;
			line-height: 51px;
			margin: 10px 0;
			position: relative;

			&:before, &:after {
				width: 45%;
			}

			&:before, &:after {
				content: "";
				top: 25px;
				left: 0;
				height: 1px;
				display: block;
				background: rgb(222, 226, 230);
				position: absolute;
			}

			&:after {
				right: 0;
				left: auto;
			}
		}

		&-table {
			width: 100%;
			display: block;
			margin-top: 50px;
			transform: scaleY(0);
			height: 0px;
			opacity: 0;
			transform-origin: top;
			@include theme-transition();

			&.extended {
				opacity: 1;
				height: auto;
				transform: scaleY(1);
			}

			@include media-breakpoint-down(md) {
				margin-top: 30px;
			}

			&-paragraph {
				width: 100%;
				text-align: justify;
				margin-top: 50px;
				margin-bottom: 50px;
			}

			&-extend {
				border-top: 1px solid darken(#fff, 10%);
				margin-top: 40px;
				padding-top: 40px;
				text-align: center;


				&-arrow {
					$arrow-height: 50px;

					width: $arrow-height;
					height: $arrow-height;
					line-height: $arrow-height;
					margin: 0 auto;
					padding: 0;
					border-radius: 50%;
					background: $color-primary;
					color: $color-text-white;
					@include theme-transition();

					&.rotated {
						transform: rotateZ(180deg);
					}

					i {
						width: $arrow-height;
						height: $arrow-height;
						line-height: $arrow-height;
						margin: 0;
						padding: 0;
						font-size: 30px;
					}
				}
			}

			table.table {
				width: 100%;

				tr {
					td {
						padding: 10px 16px;
						vertical-align: middle;

						&:first-child {
							font-weight: 500;
						}
					}

					&:first-child td {
						border-top: none;
					}
				}
			}
		}

		&-gallery {
			//margin-top: 40px;
			//
			//&-img {
			//  padding-bottom: 30px;
			//
			//  @include media-breakpoint-down(md) {
			//    padding: 0 7.5px 15px 7.5px;
			//
			//    &:nth-child(2n+1) {
			//      padding-left: 15px;
			//    }
			//
			//    &:nth-child(2n+2) {
			//      padding-right: 15px;
			//    }
			//  }
			//}
			//
			//img {
			//  width: 100%;
			//  margin: 0;
			//  padding: 0;
			//}
		}

		h1 {
			font-size: $font-size-heading-5;
			margin: 0 0 6px 0;
		}

		h3 {
			font-size: $font-size-heading-2;
			color: $color-text-dark;
			margin: 0 0 16px 0;
			text-transform: lowercase;

			&:first-letter {
				text-transform: uppercase;
			}
		}

		h2 {
			font-size: $font-size-heading-1;
			margin: 0 0 25px 0;
			color: $color-primary;
		}
	}
</style>
