<template>
	<div id="app" :class="{'scroll-disabled': !isOlderThan18Yrs}">
		<div class="app-18-older" :class="{'hidden': isOlderThan18Yrs}">
			<div class="app-18-older-container">
				<div class="app-18-older-container-text"><strong>Mám viac ako</strong> 18 rokov</div>
				
				<div class="app-18-older-container-buttons">
					<button class="yes" @click="olderThan18(true)">Áno</button>
					<button class="no"  @click="olderThan18(false)">Nie</button>
				</div>
			</div>
		</div>
		
		<transition name="fade" mode="out-in">
			<div class="app-loading-screen" v-if="pageIsLoading">
				<div class="app-loading-screen-container">
					<div class="app-loading-screen-container-icon animate__animated animate__infinite animate__flip">
						<i class="fas fa-wine-glass-alt fa-5x"></i>
					</div>
					
					<div class="app-loading-screen-container-text">
						Načítava sa...
					</div>
				</div>
			</div>
		</transition>
		
		<Header/>

		<transition name="fade" mode="out-in">
			<router-view :key="routeName"/>
		</transition>

		<notifications group="shopping-cart" position="bottom right" />

		<CartButton :visible="isShoppingCart" />

		<Footer />
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import Header from "./components/Header";
import Footer from "./components/Footer";
import api from "./services/api";
import CartButton from "@/components/CartButton";

export default {
	name: 'App',
	components: {CartButton, Footer, Header},
	data() {
		return {
			stylesheet: "",
			pageChange: false,
			isOlderThan18Yrs: false,
			pageIsLoading: false,
			pageLoadingStartTime: 0,
			pageLoadingBlockedHide: true,
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler(to) {
				this.setPageTitle(to.meta.title);
			}
		},
		getTitle: function () {
			this.setPageTitle(this.getTitle);
		},
		getAppLoading: function () {
			this.openPageLoadingScreen();
		}
	},
	computed: {
		routeName: function () {
			let route = this.$route.path.substring(1);
			let end = route.indexOf('/');
			route = route.substring(0, ((end > 0) ? end : route.length));
			return route;
		},
		isShoppingCart: function () {
			return this.$route.path.startsWith("/kosik");
		},
		...mapGetters([
			"getProductsJSON", "getTitle", "getBlogFilter", "getAppLoading"
		]),
	},
	mounted() {
		//Load customer:
		this.loadCustomer();
		
		//Check instance shopping cart:
		setInterval(this.checkShoppingCart, 500);
		
		this.isOlder();
		this.loadBlogPosts();
	},
	methods: {
		openPageLoadingScreen() {
			if(this.getAppLoading && this.$route.name == "index") {
				this.pageIsLoading = true;
				this.pageLoadingBlockedHide = true;
				this.pageLoadingStartTime = new Date();
				
				//Check if loading complete:
				let _that = this;
				
				const interval = setInterval(function () {
					let time_now = new Date();
					
					if(time_now - _that.pageLoadingStartTime > 500 && !_that.getAppLoading) {
						_that.pageIsLoading = false;
						_that.pageLoadingBlockedHide = false;
						clearInterval(interval);
					}
				}, 250);
			} else {
				if(!this.pageLoadingBlockedHide) {
					this.pageIsLoading = false;
				}
			}
		},
		setPageTitle(route_title) {
			let title = "Víno Kanich";
			let default_title = " | " + "Víno Kanich";
			
			if(route_title) {
				title = route_title + default_title;
			} else if(this.getTitle) {
				title = this.getTitle + default_title;
			}
			
			document.title = title;
		},
		loadCustomer() {
			let _that = this;
			api.getCustomer().then(res => {
				_that.$store.commit('setCustomer', res.data.customer);
			});
		},
		checkShoppingCart() {
			//Get shopping cart content:
			let products = localStorage.shoppingCartProducts;
			let stored_products = this.getProductsJSON;
			
			try {
				products = JSON.parse(products);
				stored_products = JSON.parse(stored_products);
			} catch (e) {
				return;
			}
			
			if(!products) {
				products = [];
			}
			
			//Same?
			if(!this.lodash.isEqual(products, stored_products)) {
				//Store:
				this.$store.commit('setCartProducts', products);
			}
		},
		olderThan18(res) {
			if(res) {
				localStorage.setItem('older_than_18', true);
			} else {
				window.history.back();
			}
			
			this.isOlder();
		},
		isOlder: function () {
			this.isOlderThan18Yrs = localStorage.getItem('older_than_18');
		},
		loadBlogPosts() {
			let _that = this;
			
			api.getBlog(this.getBlogFilter).then(res => {
				_that.$store.commit('setBlogPosts', res.data.posts);
				_that.$store.commit('setBlogCategories', res.data.categories);
			});
		},
	},
}
</script>

<style lang="scss">
@import "./scss/theme.scss";

.vue-notification {
	background: $color-primary !important;
	border-color: $color-primary-hover !important;
	color: $color-dark;
	border-radius: 5px;
	
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}

.app-loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1001;
	overflow: hidden;
	background: $color-dark;
	@include theme-transition();
	
	&-container {
		width: 90%;
		position: absolute;
		text-align: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $color-text-white;
		
		&-icon {
			margin-bottom: 10px;
			color: $color-primary;
		}
		
		&-text {
			@include font-primary(300);
			font-size: $font-size-heading-2;
		}
	}
}

.app-18-older {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999;
	background: rgba(#000, 0.9);
	@include theme-transition();
	
	&.hidden {
		display: none;
	}
	
	&-container {
		min-width: 350px;
		position: absolute;
		padding: 30px 0;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-top: 1px solid $color-primary;
		border-bottom: 1px solid $color-primary;
		
		&-text {
			color: $color-text-white;
			font-size: $font-size-heading-2;
			text-transform: uppercase;
			text-align: center;
			margin-bottom: 40px;
			@include font-primary(300);
			
			strong {
				color: $color-primary;
				display: block;
				margin: 0;
				padding: 0;
				width: 100%;
				font-weight: 500;
			}
		}
		
		&-buttons {
			button {
				border: none;
				outline: none;
				color: $color-text-white;
				font-size: $font-size-heading-1;
				padding: 10px 30px;
				text-transform: uppercase;
				@include theme-transition();
				
				&.yes {
					float: left;
					background: $color-primary;
					
					&:hover {
						background: $color-primary-hover;
					}
				}
				
				&.no {
					float: right;
					background: $color-dark;
					
					&:hover {
						background: $color-dark-1;
					}
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			min-width: 300px;
			
			&-buttons {
				button {
					font-size: $font-size-basic-lg;
				}
			}
		}
	}
}
</style>
