<template>
	<div class="slider-item animate__animated animate__zoomIn">
		<div class="slider-item-image">
			<div class="slider-item-image-container" @click="openInEshop">
				<ImgLazy :url="imageSrc(product.index_thumb)" />
				
				<div class="shadow" />
			</div>
		</div>
	</div>
</template>

<script>
	import ImgLazy from "./ImgLazy";
	export default {
		name: "IndexProductSliderItem",
		components: {ImgLazy},
		props: {
			product: {
				type: Object,
				required: true,
			},
			index: {
				type: Number,
				required: true,
			}
		},
		methods: {
			openInEshop() {
				this.$router.push({
					name: "product",
					params: {
						id: this.product.id
					}
				});
			},
			animationType() {
				switch (this.index) {
					case 0:
						return "animate__slideInLeft";
					case 1:
						return "animate__slideInDown";
					case 2:
						return "animate__slideInRight";
				}
			},
		},
	}
</script>

<style scoped lang="scss">
	.slider-item {
		float: left;
		
		&-image {
			height: 100%;
			
			&-container {
				height: 100%;
				display: inline-flex;
				position: relative;
				
				img {
					@include theme-transition();
					height: 100%;
					display: inline-table;
					transform-origin: bottom;
					z-index: 91;
					
					@include media-breakpoint-up(lg) {
						&:hover {
							transform: scale(1.1);
							cursor: pointer;
						}
					}
				}
				
				.shadow {
					position: absolute;
					border-radius: 50%;
					width: 115%;
					height: 130px;
					background: black;
					opacity: 0.5;
					left: 50%;
					bottom: -65px;
					z-index: 90;
					margin: 0 auto;
					filter: blur(10px);
					transform: scale(1, 0.2) translateX(-50%);
				}
			}
		}
	}
	
	.animate__animated {
		@include media-breakpoint-down(md) {
			animation: none !important;
			-webkit-animation: none !important;
			-moz-animation: none !important;
			-o-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
		}
	}
</style>