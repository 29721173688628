<template>
	<main role="main" class="container">
		<section class="page-404">
			<div class="page-404-icon">
				<i class="fas fa-times"></i>
			</div>

			<div class="page-404-text">
				Ľutujeme, ale požadovanú stránku nebolo možné nájsť.
			</div>
		</section>
	</main>
</template>

<script>
	export default {
		name: "Page404"
	}
</script>

<style scoped lang="scss">
	.page-404 {
		display: block;
		overflow: visible;
		width: 100%;
		text-align: center;

		$icon-size: 75px;

		&-icon {
			margin: 0 auto;
			width: $icon-size;
			height: $icon-size;
			line-height: $icon-size;
			color: $color-text-white;
			border-radius: 50%;
			font-size: 50px;
			background: $color-primary;
		}

		&-text {
			margin: 30px auto 0;
			@include font-headings();
			font-size: $font-size-heading-2;
			color: $color-primary;
		}
	}
</style>
