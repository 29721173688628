<template>
	<main role="main" class="container blog">
		<transition name="fade" mode="out-in" appear>
			<router-view></router-view>
		</transition>
		
		<BlogSidebar />
	</main>
</template>

<script>
	import api from "../services/api";
	import {mapGetters} from "vuex";
	import BlogSidebar from "./Blog/BlogSidebar";
	
	export default {
		name: "Blog",
		components: {BlogSidebar},
		data() {
			return {
				posts: [],
				categories: [],
			};
		},
		computed: {
			...mapGetters([
				'getPosts', 'getCategories', 'getBlogFilter',
			]),
		},
		watch: {
			getBlogFilter: {
				deep: true,
				handler() {
					this.loadBlogPosts();
				},
			}
		},
		mounted() {
			this.loadBlogPosts();
		},
		methods: {
			loadBlogPosts() {
				let _that = this;
				
				api.getBlog(this.getBlogFilter).then(res => {
					_that.$store.commit('setBlogPosts', res.data.posts);
					_that.$store.commit('setBlogCategories', res.data.categories);
				});
			},
		},
	}
</script>