const state = {
	events: [],
};

const getters = {
	getEvents: state => state.events,
	getEventsCount: state => {
		return state.events.length;
	}
};

const actions = {};

const mutations = {
	setEvents: (state, payload) => state.events = payload,
};

export default {
	state,
	getters,
	actions,
	mutations
};
