<template>
	<main role="main" class="container-fluid">
		<div class="sales-map" v-if="places.length > 0">
			<DarkGoogleMaps :places="places" :show="show_map" />
		</div>
		
		<div class="container">
			<SalesPointsGroup v-for="group in getShops" :key="group.id" :group="group"/>
		</div>
	</main>
</template>

<script>
	import SalesPointsGroup from "../components/SalesPointsGroup";
	import {mapGetters} from "vuex";
	import api from "../services/api";
	import DarkGoogleMaps from "@/components/DarkGoogleMaps";

	export default {
		name: "SalesPoints",
		components: {DarkGoogleMaps, SalesPointsGroup},
		computed: {
			...mapGetters([
				'getShops', 'getShopsLength',
			]),
		},
		data() {
			return {
				places: [],
				show_map: false,
			};
		},
		mounted() {
			this.loadShops();
		},
		methods: {
			loadShops() {
				if(this.getShopsLength == 0) {
					let _that = this;
					api.getShops().then(res => {
						//Store:
						_that.$store.commit('setShops', res.data.shops);
						
						//Generate map places:
						_that.generateMapData();
					});
				} else {
					this.generateMapData();
				}
			},
			generateMapData() {
				let shops = this.getShops;
				
				for(let i in shops) {
					for(let j in shops[i].points) {
						this.places.push({
							address: shops[i].points[j].address,
							label: '',
						});
					}
				}
				
				this.show_map = true;
			},
		},
	}
</script>

<style scoped lang="scss">
	main {
		padding-top: 0;
	}
	
	.sales-map {
		width: calc(100% + 30px);
		height: 550px;
		margin: 0 -15px 30px -15px;
		
		iframe {
			width: 100%;
			height: 450px;
		}
		
		@include media-breakpoint-down(md) {
			height: 300px;
		}
	}
</style>