<template>
    <i v-bind:class="`lineawesome-icon ${type} la-${name}`"></i>
</template>

<script>
    export default {
        name: "LineAwesomeIcon",
        props: {
            name: {
                type: String,
                default: '',
                required: true,
            },
            type: {
              type: String,
              default: 'las',
            },
        },
    }
</script>