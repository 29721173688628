const state = {
	API_is_loading: false,
	API_active_requests: 0,
	IMG_is_loading: false,
	IMG_active_requests: 0,
};

const getters = {
	getAppLoading: state => state.API_is_loading || state.IMG_is_loading,
};

const actions = {
	newRequestStarted: (context) => {
		//Active request counter:
		context.state.API_active_requests = context.state.API_active_requests + 1;

		//Any request has been started:
		context.state.API_is_loading = true;
	},
	runningRequestEnded: (context) => {
		context.state.API_active_requests = context.state.API_active_requests - 1;
		context.state.API_is_loading = context.state.API_active_requests !== 0;
	},
	newImageStartedLoading: (context) => {
		//Active request counter:
		context.state.IMG_active_requests = context.state.IMG_active_requests + 1;

		//Any request has been started:
		context.state.IMG_is_loading = true;
	},
	imageLoadingEnded: (context) => {
		context.state.IMG_active_requests = context.state.IMG_active_requests - 1;
		context.state.IMG_is_loading = context.state.IMG_active_requests !== 0;
	},
};

const mutations = {
	// setAppLoading: (state, payload) => state.API_is_loading = payload,
};

export default {
	state,
	getters,
	actions,
	mutations
};
