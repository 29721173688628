<template>
	<tbody>
		<tr class="orders-item" :class="{'opened': isOpened}">
			<td class="order-number">{{ order.order_number }}</td>
			<td>{{ order.ordered }}</td>
			<td>{{ order.status }}</td>
			<td>{{ order.payment.label }}</td>
			<td>{{ order.delivery.label }}</td>
			<td>{{ order.total_price }}</td>
			<td class="orders-item-icon" @click="openOrder">
				<i class="fas fa-angle-down"></i>
			</td>
		</tr>
		
		<tr class="orders-item orders-item-details" :class="{'opened': isOpened}">
			<td colspan="7">
				<table class="orders-item-details-list">
					<tbody>
						<tr class="orders-item-details-list-item" v-for="(product,index) in order.products" v-bind:key="product.id">
							<td>{{ product.name }}</td>
							<td>{{ product.sugar_divide }}</td>
							<td>{{ product.year }}</td>
							<td><strong>{{ formatPrice(product.price) }}</strong> / ks</td>
							<td>{{ order.products_amount[index] }}ks</td>
							<td><strong>{{ formatPrice(product.price * order.products_amount[index]) }}</strong></td>
						</tr>
					</tbody>
				</table>
			</td>
		</tr>
	</tbody>
</template>

<script>
	export default {
		name: "ProfileOrder",
		data() {
			return {
				isOpened: false,
			};
		},
		props: {
			order: {
				type: Object,
				required: true,
				default: () => {
					return {};
				},
			}
		},
		methods: {
			formatPrice: function (price) {
				//Two decimals:
				price = (Math.round(price * 100) / 100).toFixed(2) + "€";
				
				//Return formatted price:
				return price;
			},
			openOrder() {
				this.isOpened = !this.isOpened;
			}
		}
	}
</script>

<style scoped lang="scss">
	.orders {
		&-item {
			text-align: center;
			border: none;
			
			td.order-number {
				@include font-headings(300);
				color: $color-primary;
				font-size: $font-size-heading-1;
			}
			
			&-icon {
				cursor: pointer;
			}
			
			&.opened &-icon i {
				transform: rotateX(180deg);
			}
			
			&:nth-child(2n+1) {
				background: $body-bg;
				
				td {
					border-bottom: 1px solid $gray-300;
				}
				
				&.opened {
					td {
						border: none;
					}
					
					background: darken($body-bg, 10%);
				}
			}
			
			&.opened {
				background: darken(#fff, 10%);
			}
			
			&-details {
				transform: scaleZ(0);
				display: none;
				@include theme-transition();
				
				&.opened {
					display: table-row;
					transform: scaleZ(1);
					@include theme-transition();
					background: darken(#fff, 5%);
				}
				
				&-list {
					width: 100%;
					
					&-item {
						text-align: left;
						
						td {
							border: none;
							padding: 3px 12px;
						}
					}
				}
				
			}
		}
	}
</style>