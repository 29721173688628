<template>
	<div class="event" :class="{'opposite': isOpposite()}">
		<div class="container">
			<div class="row">
				<div class="event-image">
					<router-link :to="'/blog/' + event.blog_post_id">
						<ImgLazy :url="imageSrc(event.thumb)" :alt="event.title" />
					</router-link>
				</div>
				
				<div class="event-info">
					<h2>{{ event.period }}</h2>
					<h1><router-link :to="'/blog/' + event.blog_post_id">{{ event.name }}</router-link></h1>
					
					<p v-html="event.blog_post.description" />
					
					<router-link class="event-info-more" :to="'/blog/' + event.blog_post_id">Čítať viac</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ImgLazy from "./ImgLazy";
	export default {
		name: "IndexEvent",
		components: {ImgLazy},
		props: {
			event: {
				type: Object,
				required: true,
			},
			index: {
				type: Number,
				required: true,
			},
		},
		methods: {
			isOpposite() {
				return (this.index + 1) % 2 === 0;
			},
		},
	}
</script>

<style scoped lang="scss">
	.event {
		padding: 25px 0;
		margin-top: 25px;
		//margin-left: 0;
		//margin-right: 0;
		
		$image-size: 250px;
		$image-size-md: 220px;
		$space-between: 100px;
		$space-between-md: 100px;
		
		&-image {
			float: left;
			height: $image-size;
			text-align: center;
			margin-left: 15px;
			
			img {
				width: $image-size;
				object-fit: cover;
				border-radius: 50%;
				//border: 5px solid $color-primary;
				height: 100%;
				@include theme-transition();
				
				&:hover {
					transform: scale(1.1);
				}
			}
		}
		
		&-info {
			float: left;
			margin-left: $space-between;
			width: calc(100% - #{$image-size} - #{$space-between} - 15px);
			
			p {
				text-align: justify;
				margin-bottom: 25px;
			}
			
			h1 {
				color: $color-dark;
				text-transform: uppercase;
				font-size: $font-size-heading-4;
				
				a {
					text-decoration: none;
					color: $color-dark;
					
					&:hover, &:visited {
						text-decoration: none;
						color: $color-dark;
					}
				}
			}
			
			h2 {
				color: $color-primary;
				font-size: $font-size-heading-1;
				font-weight: 300;
			}
			
			&-more {
				padding: 12px 35px;
				background: $color-dark;
				color: $color-light;
				text-transform: uppercase;
				font-weight: 500;
				font-size: $font-size-basic-sm;
				@include theme-transition();
				
				&:hover, &:visited, &:active {
					text-decoration: none;
					color: $color-light;
				}
				
				&:hover {
					background: $color-primary;
				}
			}
		}
		
		@include media-breakpoint-up(md) {
			&.opposite {
				direction: rtl;
			}
			
			&.opposite &-image {
				margin-left: 0;
				direction: ltr;
			}
			
			&.opposite &-info {
				margin-right: $space-between;
				margin-left: 0;
				direction: ltr;
				text-align: right;
			}
		}
		
		@include media-breakpoint-down(md) {
			padding: 25px 10px;
			
			.row {
				margin-left: 0;
				margin-right: 0;
			}
			
			&-image {
				width: 100%;
				height: $image-size-md;
				
				img {
					width: $image-size-md;
				}
			}
			
			&-info {
				width: 100%;
				height: auto;
				padding: 25px 0;
				margin: 0;
				
				h1, h2 {
					text-align: left;
				}
			}
		}
	}
</style>