<template>
	<main role="main" class="container" v-if="getCartProductsCount > 0 || getStep() == 5 || getStep() == 6">
		<section class="shopping-cart-nav">
			<div class="shopping-cart-nav-item" :class="{'active': isActive(item), 'last-active': isLast(item), 'click-allowed': canGoToNextStep(item)}" v-for="item in navItemsVisible" :key="item.url">
				<router-link class="shopping-cart-nav-item-link" :to="item.url" @click.native="setLastCompletedStep(item.step)" v-if="canGoToNextStep(item)">
					<i class="fas fa-check" v-if="isDone(item)"></i>
					<span v-else>{{ item.step }}</span>
				</router-link>
				<div class="shopping-cart-nav-item-link" v-else>
					<i class="fas fa-check" v-if="isDone(item)"></i>
					<span v-else>{{ item.step }}</span>
				</div>

				<div class="shopping-cart-nav-item-desc">
					{{ item.title }}
				</div>
			</div>
		</section>

		<transition name="fade" mode="out-in" appear>
			<router-view></router-view>
		</transition>
	</main>
	<main role="main" class="container" v-else>
		Žiadne položky v košíku.
	</main>
</template>

<script>
	import {mapGetters} from "vuex";
	import api from "../services/api";

	export default {
		name: "ShoppingCart",
		data() {
			return {
				navItems: [
					{
						url: "/kosik/obsah",
						title: "Košík",
						step: 1,
						show: true,
						can_go_next: true,
					},
					{
						url: "/kosik/doprava",
						title: "Doprava a platba",
						step: 2,
						show: true,
						can_go_next: true,
					},
					{
						url: "/kosik/dorucenie",
						title: "Kontaktné údaje",
						step: 3,
						show: true,
						can_go_next: true,
					},
					{
						url: "/kosik/zhrnutie",
						title: "Zhrnutie",
						step: 4,
						show: true,
						can_go_next: false,
					},
					{
						url: "/kosik/hotovo",
						title: "Hotovo",
						step: 5,
						show: false,
						can_go_next: true,
					},
				],
				lastCompletedStep: 1,
			};
		},
		computed: {
			activeItem: function () {
				return this.navItems.filter(item => (item.url == this.$route.path))[0];
			},
			navItemsVisible: function () {
				return this.navItems.filter(item => (item.show === true));
			},
			...mapGetters([
				'getCartProductsCount', 'getCartProductsCountItems', 'getCartProducts',
			]),
		},
		watch: {
			getCartProducts: {
				deep: true,
				handler() {
					if(this.getStep() !== 1 && this.getCartProductsCountItems % 6 !== 0) {
						this.$router.push({name: "shopping-cart-contents"}).catch(() => {});
						this.lastCompletedStep = 1;
					}
				},
			}
		},
		mounted() {
			this.fetchSettings();
		},
		methods: {
			canGoToNextStep(item) {
				if(item.can_go_next) {
					return (item.step <= this.lastCompletedStep + 1) && (this.getCartProductsCountItems % 6 === 0);
				} else {
					return false;
				}
			},
			getStep() {
				return this.activeItem.step;
			},
			isActive(item) {
				return item.step <= this.activeItem.step;
			},
			isLast(item) {
				return item.step == this.activeItem.step;
			},
			isDone(item) {
				return item.step <= this.activeItem.step;
			},
			formatPrice: function (price) {
				//Two decimals:
				price = (Math.round(price * 100) / 100).toFixed(2) + "€";

				//Return formatted price:
				return price;
			},
			setLastCompletedStep(step) {
				if(step > this.lastCompletedStep) {
					this.lastCompletedStep = step + 1;
				}
			},
			nextStep(step) {
				//Get next step:
				let next_step = this.navItems.filter(item => (item.step == (step + 1)))[0];

				//Navigate to next step:
				this.$router.push(next_step.url).catch(() => {});

				//Set last completed step:
				this.setLastCompletedStep(step);
			},
			fetchSettings() {
				this.fetchDeliveryOptions();
				this.fetchPaymentOptions();
			},
			fetchDeliveryOptions() {
				let _that = this;
				api.getDeliveryOptions().then(res => {
					_that.$store.commit('setDeliveryOptions', res.data.delivery)
				});
			},
			fetchPaymentOptions() {
				let _that = this;
				api.getPaymentOptions().then(res => {
					_that.$store.commit('setPaymentOptions', res.data.payments)
				});
			},
		},
	}
</script>

<style scoped lang="scss">
	.slide-enter-active,
	.slide-leave-active {
		@include theme-transition();
	}

	.slide-enter,
	.slide-leave-to {
		opacity: 0;
		top: 0;
		transform: translateX(10%);
	}

	$badge-size: 40px;
	$badge-border-size: 1.5px;

	.shopping-cart-nav {
		list-style: none;
		padding: 0;
		width: 100%;
		overflow: visible;
		display: flex;
		flex-direction: row;
		margin: 0 0 50px 0;
		@include font-headings();

		&-item {
			flex: 1;
			justify-content: center;
			align-items: center;
			text-align: center;
			position: relative;
			cursor: not-allowed;
			@include theme-transition();

			&:before, &:after {
				content: "";
				display: block;
				width: calc(50% - (#{$badge-size} / 2));
				height: $badge-border-size;
				background: $color-text-dark;
				position: absolute;
				top: 50%;
				transform: translateY(calc(-50% + #{$badge-border-size}));
				left: 0;
				@include theme-transition();
			}

			&:after {
				transform: translateY(50%);
				right: 0;
				left: unset;
			}

			&:first-child:before, &:last-child:after {
				display: none;
			}

			&-link {
				margin: 0 auto;
				flex: 1;
				text-align: center;
				display: block;
				width: $badge-size;
				height: $badge-size;
				line-height: $badge-size - ($badge-border-size * 2);
				border-radius: 50%;
				border: $badge-border-size solid $color-text-dark;
				background: $color-light;
				color: $color-text-dark;
				font-size: $font-size-basic-lg;
				font-weight: 500;
				cursor: not-allowed;
				text-decoration: none;
				@include theme-transition();

				&:hover, &:active, &:visited {
					color: $color-text-dark;
					text-decoration: none;
				}
			}

			&-desc {
				position: absolute;
				top: $badge-size + 15px;
				left: 50%;
				transform: translateX(-50%);
				@include theme-transition();
				font-weight: 300;
			}

			&:hover, &.active {
				.shopping-cart-nav-item-desc {
					color: $color-primary
				}

				.shopping-cart-nav-item-link {
					color: $color-text-white;
					background: $color-primary;
					border-color: $color-primary;
				}
			}

			&.active:before, &.active:after {
				background: $color-primary;
			}

			&.last-active:after {
				background: $color-text-dark;
			}

			&.click-allowed {
				cursor: pointer;

				.shopping-cart-nav-item-link {
					cursor: pointer;
				}
			}
		}

		@include media-breakpoint-down(md) {
			margin: 0 0 80px 0;
		}
	}
</style>
