<template>
	<main role="main" class="container">
		<section class="page">
			<h1>{{ page.title }}</h1>

			<div v-html="page.contents"></div>
		</section>
	</main>
</template>

<script>
	import api from "../services/api";

	export default {
		name: "Page",
		data() {
			return {
				page: {
					title: "",
					contents: "",
				},
			};
		},
		mounted() {
			this.loadPage();
		},
		methods: {
			loadPage() {
				let _that = this;
				api.getPage(this.$route.path).then(res => {
					if(res.data.page) {
						_that.page = res.data.page;
					} else {
						_that.$router.push('/404').catch(() => {});
					}
				});
			}
		},
	}
</script>

<style scoped lang="scss">
	.page {
		width: 100%;
	}
</style>

<style lang="scss">
	figure.image {
		width: 100%;
		margin: 0 0 25px 0;
		padding: 0;

		img {
			height: auto;
			vertical-align: bottom;
			width: 100%;
			margin: 0;
			padding: 0;
		}
	}
</style>
