<template>
	<main class="container-fluid contact-container">
		<section class="container contact">
			<h1>Kontakt</h1>
			<p>
				Kontaktujte nás priamo cez formulár alebo nám zašlite e-mail.
			</p>

			<div class="contact-text">
				<div class="contact-form">
					<form action="/kontakt" class="send-mail-form" method="post">
						<div class="body_form_item">
							<input type="text" id="name" name="name" placeholder="* Meno">
							<label for="name">Vaše celé meno.</label>
						</div>

						<div class="body_form_item">
							<input type="email" id="email" name="email" placeholder="* E-mail">
							<label for="email">E-mailová adresa, na ktorú Vám zašleme odpoveď.</label>
						</div>

						<div class="body_form_item">
							<textarea id="message" name="message" rows="10" placeholder="* Správa"></textarea>
						</div>

						<div class="body_form_item">
							<input type="submit" name="submit" value="Odoslať správu">
						</div>
					</form>
				</div>

				<div class="contact-address">
					<strong>Roman Kanich - Viktória</strong>
					<span> IČO: 40 047 351</span>
					<span> DIČ: 1022798755</span>
					<span> IČ DPH: </span>
					<span>Pezinská 42</span>
					<span>902 01 Vinosady</span>
					<br />
					<span><a href="mailto:info@vinokanich.sk">info@vinokanich.sk</a></span>
					<span><a href="tel:+421905262824">+421 905 262 824</a></span>
				</div>
			</div>
		</section>
		
		<div class="contact-map">
			<DarkGoogleMaps :places="[{address: 'Víno Kanich', label: ''}]" />
		</div>
	</main>
</template>

<script>
	import DarkGoogleMaps from "@/components/DarkGoogleMaps";
	export default {
		name: "Contact",
		components: {DarkGoogleMaps},
	}
</script>

<style scoped lang="scss">
	.contact-container {
		padding-bottom: 0;
		margin-bottom: 0;
	}
	
	.contact-map {
		width: calc(100% + 30px);
		height: 550px;
		margin: 0 -15px 0 -15px;
		
		iframe {
			width: 100%;
			height: 450px;
		}
		
		@include media-breakpoint-down(md) {
			height: 300px;
		}
	}

	.contact {
		width: 100%;
		padding-bottom: 25px;

		.contact-text {
			width: 100%;
			display: inline-block;

			.contact-form {
				width: 70%;
				margin: 0 5% 0 0;
				float: left;

				input {
					&[type=text], &[type=email]{
						margin: 0;
						width: 100%;
						@include font-primary();
						font-size: $font-size-basic;
						padding: 10px 12px;
						outline: none;
						border: 0.5px solid $color-text-light;
						@include theme-transition();
					}

					&[type=submit] {
						padding: 17.5px 50px;
						@include font-primary();
						font-size: $font-size-basic-lg;
						text-transform: uppercase;
						border: none;
						outline: none;
						background: $color-primary;
						color: $color-text-white;
						@include theme-transition();

						&:hover {
							background: $color-primary-hover;
						}
					}
				}
				
				label {
					font-size: $font-size-basic;
					padding-bottom: 15px;
					padding-left: 5px;
				}

				textarea {
					margin: 0;
					width: 100%;
					@include font-primary();
					font-size: $font-size-basic;
					padding: 10px 12px;
					outline: none;
					border: 0.5px solid $color-text-light;
					@include theme-transition();
					resize: vertical;
				}

				@include media-breakpoint-down(md) {
					margin: 0 0 30px 0;
					max-width: 100%;
					width: 100%;
				}
			}

			.contact-address {
				max-width: 25%;
				float: right;

				strong {
					display: block;
					font-weight: 400;
					font-size: $font-size-bigger;
					text-transform: uppercase;
					color: $color-primary;
					margin-bottom: 5px;
				}

				span {
					display: block;
					margin-bottom: 5px;

					a {
						text-decoration: none;
						color: $color-dark;
						@include theme-transition();

						&:active, &:visited {
							text-decoration: none;
							color: $color-dark;
						}

						&:hover {
							text-decoration: none;
							color: $color-primary-hover;
						}
					}
				}

				@include media-breakpoint-down(md) {
					float: none;
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}
</style>
