<template>
	<div class="shopping-cart-hoverbox" :class="{'expanded': visible}">
		<CartHoverBoxItem v-for="item in getCartProducts" :key="item.product.id" :item="item" />
		
		<div class="shopping-cart-hoverbox-subtotal">
			<h1>Medzisúčet: <span>{{ formatPrice(getSubtotal) }}</span></h1>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex';
import CartHoverBoxItem from "@/components/CartHoverBoxItem";

export default {
	name: "CartHoverBox",
	components: {CartHoverBoxItem},
	data() {
		return {
			amount: 1,
		};
	},
	watch: {
		getCartProducts: {
			deep: true,
			handler() {
				this.$emit("reloadProducts", true);
			},
		}
	},
	computed: {
		...mapGetters([
			'getSubtotal', 'getCartProducts',
		])
	},
	props: {
		visible: {
			type: Boolean,
			default: false,
		}
	},
	methods: {
		formatPrice: function (price) {
			//Two decimals:
			price = (Math.round(price * 100) / 100).toFixed(2) + "€";
			
			//Return formatted price:
			return price;
		},
	},
}
</script>

<style scoped lang="scss">
	.shopping-cart-hoverbox {
		position: absolute;
		top: $header-height-primary;
		right: -20px;
		width: 350px;
		padding: 20px;
		max-height: 550px;
		overflow: auto;
		background: $color-light;
		z-index: 99;
		box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
		@include theme-transition();
		transform: scale(0, 0);
		transform-origin: top right;
		
		&.expanded {
			transform: scale(1, 1);
		}
		
		&-subtotal {
			width: 100%;
			margin: 0;
			text-align: right;
			
			h1 {
				@include font-primary();
				font-size: $font-size-basic-lg;
				color: $color-dark;
				
				span {
					@include font-primary(500);
					font-size: $font-size-heading-1;
					color: $color-primary;
					margin-left: 25px;
					display: inline-block;
				}
			}
		}
	}
</style>