const state = {
	items: [],
	payment: null,
	payment_options: [],
	delivery: null,
	delivery_options: [],
	products: [],
	discount: false,
};

const getters = {
	getSubtotal: state => {
		let price = 0;

		//For each item, count:
		let item = null;
		for(let i in state.products) {
			//Select item:
			item = state.products[i];

			//Add to price:
			price += item.product.price * item.amount;
		}

		//Price:
		return Number(price);
	},
	getTotal: state => in_total => {
		let price = 0;

		//For each item, count:
		let item = null;
		for(let i in state.products) {
			//Select item:
			item = state.products[i];

			//Add to price:
			price += Number(item.product.price) * item.amount;
		}

		if(in_total && state.discount) {
			price = price *  state.discount.discount;
		}

		//Is payment set?
		if(state.payment) {
			price += Number(state.payment.price);
		}

		//Is delivery set?
		if(state.delivery) {
			price += Number(state.delivery.price);
		}

		//Price:
		return Number(price);
	},
	getPayment: state => state.payment,
	getDelivery: state => state.delivery,
	getCartProduct: state => id => {
		return state.products.filter(item => (item == id))[0];
	},
	getCartProducts: state => state.products,
	getCartProductsCount: state => state.products.length,
	getCartProductsCountItems: state => {
		let count = 0;

		//Count each product type:
		for(let i in state.products) {
			count += state.products[i].amount;
		}

		return count;
	},
	getPaymentOptions: state => state.payment_options,
	getDeliveryOptions: state => state.delivery_options,
	getProductsJSON: state => {
		return JSON.stringify(state.products);
	},
	getDiscount: state => state.discount,
};

const actions = {
	addCartProduct: (context, data) => {
		//Amount is number:
		data.amount = Number(data.amount);

		//Find item:
		let item = context.state.products.filter(item => (item.product.id == data.product.id))[0];

		//Item found?
		if(item) {
			//Get index:
			let index = context.state.products.indexOf(item);

			//Add amount:
			context.state.products[index].amount += data.amount;
		} else {
			context.state.products.push(data);
		}

		localStorage.setItem("shoppingCartProducts", JSON.stringify(context.state.products));
	},
	updateCartProduct: (context, data) => {
		//Amount is number:
		data.amount = Number(data.amount);

		//Find item:
		let item = context.state.products.filter(item => (item.product.id == data.product.id))[0];

		//Item found?
		if(item) {
			//Get index:
			let index = context.state.products.indexOf(item);

			//Add amount:
			context.state.products[index].amount = data.amount;
		} else {
			context.state.products.push(data);
		}

		localStorage.setItem("shoppingCartProducts", JSON.stringify(context.state.products));
	},
	removeCartProduct: (context, data) => {
		//Find item:
		let item = context.state.products.filter(item => (item.product.id == data.product.id))[0];

		//Item found?
		if(item) {
			//Get index:
			let index = context.state.products.indexOf(item);

			//Remove item:
			context.state.products.splice(index, 1);
		}

		localStorage.setItem("shoppingCartProducts", JSON.stringify(context.state.products));
	},
	truncateCart: (context, data) => {
		if(data) {
			context.state.products = [];
			localStorage.setItem("shoppingCartProducts", JSON.stringify(context.state.products));
		}
	}
};

const mutations = {
	setDelivery: (state, payload) => state.delivery = payload,
	setPayment : (state, payload) => state.payment  = payload,
	setCartProducts: (state, payload) => state.products = payload,
	setPaymentOptions: (state, payload) => state.payment_options = payload,
	setDeliveryOptions: (state, payload) => state.delivery_options = payload,
	setDiscountCoupon: (state, payload) => state.discount = payload,
};

export default {
	state,
	getters,
	actions,
	mutations
};
