<template>
	<header class="navbar-expand-lg align-items-center w-100">
		<div class="overlay display-md" :class="{'show': navbarVisible}" @click="navbarToggle">

		</div>

		<div class="header-secondary">
			<div class="container">
				<ul v-if="getCustomerId == 0">
					<li><router-link to="/prihlasenie">Prihlásenie</router-link></li>
				</ul>
				<ul v-else>
					<li>Prihlásený ako: <strong><router-link to="/profil">{{ getCustomer.name + " " + getCustomer.surname }}</router-link></strong></li>
					<li class="clickable" @click="myOrders">Moje objednávky</li>
					<li class="clickable" @click="logout">Odhlásiť sa</li>
				</ul>
			</div>
		</div>

		<div class="header-primary">
			<div class="container">
				<div class="brand">
					<router-link to="/">
						<img src="@/assets/img/logo.png" class="brand-logo" />

						<img src="@/assets/svg/logo_text.svg" class="brand-logo-text" />
					</router-link>
				</div>

				<button class="navbar-toggler" type="button" @click="navbarToggle">
					<i class="fas fa-bars"></i>
				</button>

				<div id="navbar-primary" class="navbar-collapse ml-auto" :class="{'show': navbarVisible}">
					<div class="display-md navbar-primary-heading">Menu</div>
					<ul class="navbar-nav ml-auto navbar-menu">
						<li class="nav-item"><router-link class="nav-link" to="/" exact>Domov</router-link></li>
						<li class="nav-item"><router-link class="nav-link" to="/e-shop">E-shop</router-link></li>
						<li class="nav-item"><router-link class="nav-link" to="/blog">Blog</router-link></li>
						<li class="nav-item"><router-link class="nav-link" to="/kontakt">Kontakt</router-link></li>
						<li class="nav-item"><router-link class="nav-link" to="/kde-kupit">Kde kúpiť?</router-link></li>
						<li class="nav-item nav-item-icon" @mouseenter="cartHoverBoxVisible = true" @mouseleave="cartHoverBoxVisible = false">
							<router-link class="nav-link hide-md" to="/kosik" v-if="getCartProductsCount > 0">
								<i class="fas fa-shopping-cart"></i>

								<div class="badge-items">{{ getCartProductsCount }}</div>
							</router-link>
							
							<div class="nav-link hide-md" v-else>
								<div class="badge-items">{{ getCartProductsCount }}</div>
								<i class="fas fa-shopping-cart"></i>
							</div>

							<router-link class="nav-link display-md" to="/kosik" v-if="getCartProductsCount > 0">
								Nákupný košík

								<span class="badge-items">{{ getCartProductsCount }}</span>
							</router-link>
							
							<div class="nav-link display-md" v-else>
								Nákupný košík
								
								<span class="badge-items">{{ getCartProductsCount }}</span>
							</div>
							
							<CartHoverBox :visible="getCartProductsCount > 0 && cartHoverBoxVisible"/>
						</li>
					</ul>

					<div class="display-md navbar-primary-heading">Zákaznícka zóna</div>
					<ul class="display-md navbar-nav navbar-actions ml-auto" v-if="getCustomerId == 0">
						<li><router-link to="/prihlasenie">Prihlásenie</router-link></li>
					</ul>
					<ul class="display-md navbar-nav navbar-actions ml-auto" v-else>
						<li><strong @click="myAccount">{{ getCustomer.name + " " + getCustomer.surname }}</strong></li>
						<li @click="myOrders">Moje objednávky</li>
						<li @click="logout">Odhlásiť sa</li>
					</ul>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
	import {mapGetters} from "vuex";
	import api from "@/services/api";
	import CartHoverBox from "@/components/CartHoverBox";

	export default {
		name: "Header",
		components: {CartHoverBox},
		data() {
			return {
				navbarVisible: false,
				cartHoverBoxVisible: false,
			};
		},
		computed: {
			...mapGetters([
				'getCartProductsCount', 'getCustomerId', 'getCustomer', 'getSubtotal',
			]),
		},
		watch: {
			'$route': function () {
				this.navbarVisible = false;
			},
		},
		methods: {
			navbarToggle() {
				this.navbarVisible = !this.navbarVisible;
			},
			logout() {
				//Request:
				let _that = this;
				// eslint-disable-next-line no-unused-vars
				api.postCustomer({logout: 1}).then(res => {
					_that.$store.commit('setCustomer', null);
					_that.navbarVisible = false;
				});
			},
			myAccount() {
				this.$router.push("/profil").catch(() => {});
			},
			myOrders() {
				this.$router.push("/profil/objednavky").catch(() => {});
			},
			formatPrice: function (price) {
				//Two decimals:
				price = (Math.round(price * 100) / 100).toFixed(2) + "€";
				
				//Return formatted price:
				return price;
			},
		},
	}
</script>

<style scoped lang="scss">
	header {
		height: $header-height-primary + $header-height-secondary;
		box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
		position: fixed;
		overflow: visible;
		z-index: 99;

		.overlay {
			z-index: 98;
			position: fixed;
			width: 100%;
			height: calc(100vh - #{$header-height-primary});
			top: $header-height-primary;
			left: 0;
			background: rgba(0, 0, 0, 0.35);
			visibility: hidden;
			opacity: 0;
			@include theme-transition();

			&.show {
				visibility: visible;
				opacity: 1;
			}
		}

		.header-secondary {
			width: 100%;
			margin: 0;
			padding: 0;
			background: $color-dark-1;
			color: lighten($color-dark-1, 25%);
			height: $header-height-secondary;
			line-height: $header-height-secondary;
			font-size: $font-size-basic-sm;
			@include font-navigation();

			.container {
				overflow: hidden;
			}

			ul {
				list-style: none;
				float: right;

				li {
					float: left;
					margin: 0;
					padding: 0;

					&:not(:first-child):after {
						content: "|";
						margin: 0 12px;
						display: inline-block;
						float: left;
					}

					&.clickable {
						cursor: pointer;
					}

					a {
						text-decoration: none;
						color: lighten($color-dark-1, 25%);
						@include theme-transition();

						&:hover {
							color: $color-primary-hover;
						}
					}

					strong {
						cursor: pointer;
						margin-left: 10px;
					}
				}
			}

			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		.header-primary {
			width: 100%;
			margin: 0;
			padding: 0;
			background: $color-dark;
			height: $header-height-primary;
			z-index: 99;

			.brand {
				margin: 0;
				padding: 10px 0;
				float: left;
				cursor: pointer;
				height: $header-height-primary;
				position: relative;

				img, svg {
					margin: 0;
					padding: 0;
					height: 100%;
				}

				.brand-logo {
					height: $header-height-primary - 20px;
				}

				.brand-logo-text {
					margin-left: 15px;
					height: 20px;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.navbar-toggler {
				height: $header-height-primary;
				color: $color-primary;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				outline: none;
				border: none;

				&:focus, &:hover {
					outline: none;
					border: none;
				}
			}

			.navbar-nav {
				@include font-navigation();

				> li:last-child {
					padding-right: 0;
				}

				> li:first-child {
					padding-left: 0;
				}

				.nav-item {
					padding: 0 22px;
					color: $color-text-white;
					text-transform: uppercase;
					font-size: $font-size-basic-lg;
					height: $header-height-primary;
					line-height: $header-height-primary;

					&-icon {
						position: relative;
						
						i {
							font-size: $font-size-bigger;
						}
					}

					.nav-link {
						padding: 0;
						margin: 0;
						color: $color-text-white;
						@include theme-transition();

						&:visited {
							color: $color-text-white;
						}

						&:hover, &:active, &.active {
							color: $color-primary;
						}
					}
				}
			}
		}

		#navbar-primary {
			&.show {
				@include theme-transition();
			}

			.navbar-menu {
				.nav-link.hide-md {
					position: relative;
					overflow: visible;

					.badge-items {
						position: absolute;
						top: 10px;
						right: 0;
						background: $color-primary;
						display: block;
						line-height: 20px;
						padding: 0 6px;
						height: 20px;
						border-radius: 10px;
						font-size: $font-size-small;
						transform: translateX(15px);
						color: $color-text-white;

						&:hover {
							background: $color-primary-hover;
							color: $color-text-white;
						}
					}
				}
			}
		}

		@include media-breakpoint-down(md) {
			height: $header-height-primary;

			#navbar-primary {
				position: fixed;
				top: $header-height-primary;
				padding: 15px;
				left: 0;
				background: $color-dark-1;
				width: 100vw;
				z-index: 98;
				color: $color-text-white;
				font-size: $font-size-basic-lg;
				box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
				@include slide-down-animation();

				.navbar-primary-heading {
					color: $color-primary;
					text-transform: uppercase;
					font-size: $font-size-heading-1;
					font-weight: 500;
				}

				.navbar-actions {
					margin: 0;

					li {
						margin: 4px 0;

						&:first-child {
							margin-top: 0;
						}

						a {
							color: $color-text-white;
							text-decoration: none;

							&:hover {
								color: $color-primary-hover;
							}
						}
					}
				}

				.navbar-menu {
					margin-bottom: 25px;

					li {
						padding: 0;
						line-height: unset;
						height: auto;
						text-transform: lowercase;
						margin: 4px 0;
						position: relative;

						.badge-items {
							margin-left: 16px;
							padding: 0 10px;
							border-radius: 10px;
							height: 20px;
							line-height: unset;
							display: inline-block;
							background: $color-primary;
							color: $color-text-white;
							position: absolute;
							top: 50%;
							font-size: $font-size-basic-sm;
							transform: translateY(-50%);
						}

						&:first-child {
							margin-top: 0;
						}

						&:first-letter {
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
</style>
