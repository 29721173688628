<template>
	<img :src="img_link" :alt="alt" class="img__lazy__loaded"/>
</template>

<script>
	export default {
		name: "ImgLazy",
		data() {
			return {
				img_link: null,
			};
		},
		props: {
			url: {
				type: String,
				required: true,
			},
			alt: {
				type: String,
				required: false,
				default: null,
			},
		},
		created() {
			this.getImgUrl();
		},
		methods: {
			async getImgUrl() {
				let _that = this;
				
				new Promise((resolve) => {
					//Let application know that we're loading a new image:
					_that.$store.dispatch('newImageStartedLoading');
					
					//Create img object:
					let image = new Image();
					image.src = _that.url;
					
					//Image is loaded, put link:
					image.onload = () => {
						_that.img_link = _that.url;
						_that.$store.dispatch('imageLoadingEnded');
					}
					
					//Image could not be loaded!
					image.onerror = () => {
						_that.img_link = _that.url;
						_that.$store.dispatch('imageLoadingEnded');
					}
					
					resolve(image);
				});
			}
		}
	}
</script>

<style scoped>

</style>