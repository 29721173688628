const state = {
	customer: {
		id: 0,
		name: "",
		surname: "",
		email: "",
		phone: "",
		street: "",
		city: "",
		postal: "",
		company: "",
		company_name: "",
		company_ico: "",
		company_dic: "",
		company_icdph: "",
		delivery_address: "",
		delivery_address_street: "",
		delivery_address_city: "",
		delivery_address_postal: "",
		delivery_address_phone: "",
	},
};

const getters = {
	getCustomer: state => state.customer,
	getCustomerId: state => state.customer.id,
};

const actions = {};

const mutations = {
	setCustomer: (state, payload) => {
		if (!payload) {
			payload = {
				id: 0,
				name: "",
				surname: "",
				email: "",
				phone: "",
				street: "",
				city: "",
				postal: "",
				company: "",
				company_name: "",
				company_ico: "",
				company_dic: "",
				company_icdph: "",
				delivery_address: "",
				delivery_address_street: "",
				delivery_address_city: "",
				delivery_address_postal: "",
				delivery_address_phone: "",
			};
		}

		state.customer = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations
};
