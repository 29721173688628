const state = {
	posts: [],
	categories: [],
	filter: {
		category_id: 0,
	},
};

const getters = {
	getPosts: state => state.posts,
	getPost: state => id => {
		return state.posts.filter(post => (post.id == id))[0];
	},
	getCategories: state => state.categories,
	getBlogFilter: state => state.filter,
};

const actions = {};

const mutations = {
	setBlogPosts: (state, payload) => state.posts = payload,
	setBlogCategories: (state, payload) => state.categories = payload,
	setBlogFilter: (state, payload) => state.filter = payload,
};

export default {
	state,
	getters,
	actions,
	mutations
};
