<template>
	<div class="blog-post">
		<div class="blog-post-heading">
			<h2>{{ post.category.name }} / {{ post.author_name }}</h2>
			<h1>{{ post.title }}</h1>
		</div>
		
		<div class="blog-post-img">
			<div class="blog-post-date">
				<span class="day">{{ post.created.day }}</span>
				<span class="month">{{ post.created.month }}</span>
			</div>
			
			<ImgLazy :url="imageSrc(post.thumb)" :alt="post.title" />
		</div>
		
		<div class="blog-post-text" v-html="post.contents" />
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	import ImgLazy from "../../components/ImgLazy";

	export default {
		name: "BlogPost",
		components: {ImgLazy},
		data() {
			return {
				post: {
					created: {
						day: "",
						month: "",
					},
					category: {
						name: ""
					},
					author_name: "",
					thumb: "",
					contents: "",
					title: "",
				},
			};
		},
		computed: {
			...mapGetters([
				'getPost', 'getPosts',
			]),
		},
		mounted() {
			this.loadPost();
		},
		watch: {
			getPosts: {
				deep: true,
				handler: function () {
					this.loadPost();
				},
			},
			"$route.params.id": function () {
				this.loadPost();
			}
		},
		methods: {
			loadPost() {
				let post = this.getPost(this.$route.params.id);
				
				if(post) {
					this.post = post;
					this.$store.commit("setTitle", post.title);
				}
			},
		}
	}
</script>

<style scoped lang="scss">
	.blog-post {
		width: calc(100% - 350px);
		float: right;
		
		&-heading {
			width: 100%;
			
			h2 {
				color: $color-dark;
				font-size: $font-size-basic-sm;
				text-transform: uppercase;
				width: calc(100% - 90px);
				@include font-headings(300);
				margin: 0 0 15px 90px;
				text-align: center;
				display: block;
				cursor: default;
				width: calc(100% - 90px);
				
				@include media-breakpoint-down(md) {
					width: 100%;
					margin-left: 0;
					text-align: center;
					display: block;
				}
			}
			
			h1 {
				width: calc(100% - 90px);
				color: $color-dark;
				text-transform: uppercase;
				@include font-headings(300);
				margin: 0 0 20px 90px;
				padding: 0;
				text-align: center;
				font-size: $font-size-heading-2;
				cursor: pointer;
				
				&:hover {
					color: $color-primary;
					@include theme-transition();
				}
				
				@include media-breakpoint-down(md) {
					width: 100%;
					margin-left: 0;
					margin-bottom: 50px;
					text-align: center;
					display: block;
				}
			}
		}
		
		&-img {
			position: relative;
			width: 100%;
			
			.blog-post {
				&-date {
					z-index: 91;
					position: absolute;
					background: $color-dark;
					width: 50px;
					text-align: center;
					color: $white;
					top: 0;
					margin: 0;
					padding: 5px 0;
					transform: translateY(-50%);
					left: 20px;
					
					.day, .month {
						display: block;
						width: 100%;
					}
					
					.day {
						font-size: $font-size-heading-2;
					}
					
					.month {
						font-size: $font-size-basic-lg;
					}
					
					@include media-breakpoint-down(md) {
						width: 35px;
						
						.day {
							font-size: $font-size-heading-1;
						}
						
						.month {
							font-size: $font-size-basic-sm;
						}
					}
				}
			}
			
			img {
				width: 100%;
				z-index: 90;
				@include theme-transition();
				cursor: pointer;
				
				&:hover {
					filter: brightness(70%);
				}
			}
		}
		
		&-text {
			padding: 15px 0 0 0;
			width: 100%;
			text-align: justify;
		}

    @include media-breakpoint-down(md) {
      width: 100%;
    }
	}
</style>