<template>
	<div class="sale-point col-lg-3 col-xs-12 col-md-12">
		<h2 class="title" v-if="point.name">{{ point.name }}</h2>
		<span v-if="point.address">{{ point.address }}</span>
		<span v-if="point.note"><strong>Poznámka: </strong>{{ point.note }}</span>

		<h2 v-if="point.contact_phone || point.contact_email || point.contact_web.url">Kontakt:</h2>
		<span v-if="point.contact_phone"><strong>Tel. č.:</strong> <a :href="'tel:' + ((point.contact_phone).replace(/\s/g,''))">{{ point.contact_phone }}</a></span>
		<span v-if="point.contact_email"><strong>E-mail:</strong> <a :href="'mailto:' + point.contact_email">{{ point.contact_email }}</a></span>
		<span v-if="point.contact_web.url"><strong>Web:</strong> <a :href="point.contact_web.link" target="_blank">{{ point.contact_web.url }}</a></span>
	</div>
</template>

<script>
	export default {
		name: "SalePoint",
		props: {
			point: {
				type: Object,
				required: true,
			}
		}
	}
</script>

<style scoped lang="scss">
	.sale-point {
		float: left;
		margin-bottom: 15px;

		h2 {
			@include font-primary();
			font-size: $font-size-bigger;
			color: $color-primary;
			margin: 8px 0;
			padding: 0;

			&.title {
				margin: 0;
				text-transform: uppercase;
				color: $color-dark;
			}
		}

		span {
			display: block;
			width: 100%;
			margin: 5px 0;
			padding: 0;

			strong {
				font-weight: 400;
			}

			a {
				color: $color-dark;
				@include theme-transition();

				&:hover {
					color: $color-primary-hover;
					text-decoration: none;
				}
			}
		}
	}
</style>
