<template>
	<div class="wine col-md-6 col-sm-6 col-lg-4 col-xl-4 col-xs-12">
		<div class="wine-container">
			<div class="wine-thumbnail">
				<router-link :to="'/produkt/' + product.id">
					<ImgLazy :url="imageSrc(product.img_thumb)" :alt="product.name" />
				</router-link>
			</div>

			<div class="wine-details">
				<h4><router-link :to="'/produkt/' + product.id">{{ product.name }}</router-link></h4>

				<div class="wine-tag">{{ product.sugar_divide }}</div>
				<div class="wine-tag">{{ product.year }}</div>
				<div class="wine-price"><strong>{{ formatPrice(product.price) }}</strong> / ks</div>
				
				<div class="wine-buy">
					<input type="number" min="1" v-model="amount" />
					<button type="button" class="btn" @click="addToCart"><i class="fas fa-shopping-cart"></i></button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ImgLazy from "./ImgLazy";
	export default {
		name: "ProductCard",
		components: {ImgLazy},
		props: {
			product: {
				type: Object,
				required: true,
				default: function () {
					return {
						id: 0,
						name: "",
						img_thumb: "",
						description_short: "",
						category_name: "",
						img_gallery: null,
					};
				}
			},
		},
		data() {
			return {
				amount: 1,
			};
		},
		methods: {
			addToCart() {
				this.$store.dispatch('addCartProduct', {
					amount: this.amount,
					product: this.product,
				});
				this.$notify({
					group: 'shopping-cart',
					title: "Položka " + this.product.name + " bola pridaná do košíka",
					text: "Bolo pridaných " + this.amount + "ks do košíka.",
					duration: 5000,
				});
			},
			formatPrice: function (price) {
				//Free or not?
				if(price > 0) {
					//Two decimals:
					price = (Math.round(price * 100) / 100).toFixed(2) + "€";
				} else {
					price = "zdarma";
				}

				//Return formatted price:
				return price;
			},
		},
	}
</script>

<style scoped lang="scss">
	.wine {
		display: inline-block;
		height: 265px;
		margin: 0 0 70px 0;
		max-width: 100%;

		.wine-container {
			float: left;
			display: block;
			overflow: visible;
			position: relative;
			width: 100%;
			max-width: 100%;

			.wine-thumbnail {
				float: left;
				width: 60px;
				height: 265px;

				img {
					width: 100%;
					max-height: 265px;
				}
			}

			.wine-details {
				max-width: calc(100% - 90px);
				width: calc(100% - 100px);
				color: $color-text-dark;
				float: left;
				margin: 0;
				position: absolute;
				bottom: 0;
				left: 90px;

				h4 {
					color: $color-primary;
					font-size: $font-size-bigger;
					text-transform: uppercase;
					padding: 0;
					margin: 0 0 10px 0;

					a {
						color: $color-primary;
						text-decoration: none;

						&:hover, &:visited, &:active {
							color: $color-primary;
							text-decoration: none;
						}
					}
				}

				.wine-tag {
					width: 100%;
					margin: 0;
					padding: 0;
					text-transform: lowercase;

					&::first-letter {
						text-transform: uppercase;
					}
				}

				.wine-price {
					width: 100%;
					margin: 15px 0 25px 0;

					strong {
						color: $color-primary;
						font-weight: 400;
						font-size: $font-size-bigger;
					}
				}

				@include wine-buy($buy-vignette-height, $buy-vignette-height, 15px, $font-size-basic, 22px);

				.wine-out-of-stock {
					width: 100%;
					margin: 0;
					padding: 0;
					height: $buy-vignette-height;
					line-height: $buy-vignette-height;
				}
			}
			
			@include media-breakpoint-down(md) {
				width: 250px;
				max-width: 250px;
			}
		}

		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: center;
		}
	}
</style>
