import Vue from 'vue'
import Vuex from 'vuex'

import shopping_cart from "./modules/shopping-cart";
import products from "./modules/products";
import customer from "./modules/customer";
import pages from "./modules/pages";
import blog from "./modules/blog";
import events from "./modules/events";
import application from "./modules/application";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    shopping_cart,
    products,
    customer,
    pages,
    blog,
    events,
    application,
  },
});

export default store;