const state = {
	products: [{
		id: 0,
		name: "",
	}],
	product_filters: {
		all: [],
	},
	product_filters_act: [],
	products_index: [{
		id: 0,
		name: "",
	}],
};

const getters = {
	getProducts: state => state.products,
	getProductsOnIndex: state => state.products_index,
	getProductActiveFilters: state => state.product_filters_act,
	getProductFilters: state => state.product_filters,
	getProductsOnIndexCount: state => {
		let count = 0;

		for(let i in state.products_index) {
			if(state.products_index[i]) {
				count++;
			}
		}

		if(count === 1 && state.products_index[0] && state.products_index[0].id == 0) {
			return 0;
		}

		return count;
	},
	getProductsCount: state => {
		let count = 0;

		for(let i in state.products) {
			if(state.products[i]) {
				count++;
			}
		}

		if(count === 1 && state.products[0] && state.products[0].id == 0) {
			return 0;
		}

		return count;
	},
	getProductById: state => id => {
		for(let i in state.products) {
			for(let j in state.products[i].products) {
				if(state.products[i].products[j].id == id) {
					return state.products[i].products[j];
				}
			}
		}
		return false;
	},
	getCategoryById: state => id => {
		for(let i in state.products) {
			if(state.products[i].id == id) {
				return state.products[i];
			}
		}

		return false;
	},
	getFilterByCode: state => code => {
		if(state.product_filters.all && state.product_filters.all.length > 0) {
			let item = state.product_filters.all.find(el => el.code == code);

			if(item) {
				return item.title;
			}
		}

		return "";
	},
};

const actions = {};

const mutations = {
	setProducts: (state, payload) => state.products = payload,
	setProductsOnIndex: (state, payload) => state.products_index = payload,
	setProductFilters: (state, payload) => state.product_filters = payload,
	setProductActiveFilters: (state, payload) => state.product_filters_act = payload,
};

export default {
	state,
	getters,
	actions,
	mutations
};
