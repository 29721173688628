<template>
	<div class="blog-posts">
		<BlogPostThumb v-for="post in getPosts" :key="post.id" :post="post" />
	</div>
</template>

<script>
import {mapGetters} from "vuex";
	import BlogPostThumb from "../../components/BlogPostThumb";
	
	export default {
		name: "BlogPostsList",
		components: {BlogPostThumb},
		computed: {
			...mapGetters([
				'getPosts'
			])
		},
	}
</script>

<style scoped lang="scss">
	.blog-posts {
		width: calc(100% - 350px);
		float: right;
		
		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}
</style>