<template>
	<div class="cart-button display-md" :class="{'hidden': visible, 'animate__wobble': is_animated, 'animate__animated': is_animated}">
		<router-link class="cart-button-link" to="/kosik" v-if="getCartProductsCount > 0">
			<i class="fas fa-shopping-cart"></i>
			
			<div class="cart-button-link-badge">{{ getCartProductsCount }}</div>
		</router-link>
		
		<div class="cart-button-link" v-else>
			<i class="fas fa-shopping-cart"></i>
			
			<div class="cart-button-link-badge">{{ getCartProductsCount }}</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from "vuex";
	import "animate.css";
	
	export default {
		name: "CartButton",
		data() {
			return {
				is_animated: false,
			};
		},
		props: {
			visible: {
				type: Boolean,
				default: true,
			},
		},
		computed: {
			...mapGetters([
				'getCartProductsCount', 'getCartProductsCountItems'
			])
		},
		watch: {
			getCartProductsCountItems: function (val) {
				if(val > 0) {
					this.itemAdded();
				}
			},
		},
		methods: {
			itemAdded() {
				//Animate:
				this.is_animated = !this.visible;
				
				//After n sec, disabled:
				let _that = this;
				setTimeout(function () {
					_that.is_animated = false;
				}, 2000);
			},
			formatPrice: function (price) {
				//Two decimals:
				price = (Math.round(price * 100) / 100).toFixed(2) + "€";
				
				//Return formatted price:
				return price;
			},
		}
	}
</script>

<style scoped lang="scss">
	.cart-button {
		$cart-button-size: 50px;
		width: $cart-button-size;
		height: $cart-button-size;
		line-height: $cart-button-size;
		position: fixed;
		bottom: 20px;
		right: 20px;
		border-radius: 50%;
		background: $color-primary;
		text-align: center;
		z-index: 1001;
		@include theme-transition();
		
		&.hidden {
			transform: scale(0, 0);
		}
		
		&:hover {
			background: $color-primary-hover;
		}
		
		&-link {
			width: $cart-button-size;
			height: $cart-button-size;
			line-height: $cart-button-size;
			position: relative;
			font-size: $font-size-bigger;
			color: $color-text-light;
			display: block;
			
			&:hover, &:visited, &:active {
				color: $color-text-light;
			}
			
			&-badge {
				position: absolute;
				top: 0;
				right: 0px;
				transform: translateY(-50%);
				background: $color-dark;
				display: block;
				line-height: 20px;
				padding: 0 6px;
				height: 20px;
				border-radius: 10px;
				font-size: $font-size-small;
				color: $color-text-white;
			}
		}
	}
	
	div.cart-button-link {
		background-color: $color-primary;
		color: $color-text-white;
		border-radius: 50%;
		
		&:hover {
			background-color: $color-primary;
		}
	}
</style>