<template>
	<footer class="footer">
		<div class="container footer-main">
			<div class="footer-item float-left">
				<h1>Menu</h1>
				
				<div class="footer-nav">
					<ul class="navbar-nav">
						<li class="nav-item">
							<router-link class="nav-link" to="/">Domov</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/e-shop">E-shop</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/blog">Blog</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/kontakt">Kontakt</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/kde-kupit">Kde kúpiť?</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/obchodne-podmienky">Obchodné podmienky</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/reklamacny-poriadok">Reklamačný poriadok</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/dodacie-a-platobne-podmienky">Dodacie a platobné podmienky</router-link>
						</li>
						<li class="nav-item">
							<router-link class="nav-link" to="/ochrana-osobnych-udajov">Ochrana osobných údajov</router-link>
						</li>
					</ul>
				</div>
			</div>
			
<!--			<div class="footer-item float-center">-->
<!--				<div class="footer-map">-->
<!--					<h1>Kde nás nájdete?</h1>-->
<!--					<DarkGoogleMaps :places="[{address: 'Víno Kanich', label: ''}]" :controls="false" />-->
<!--				</div>-->
<!--			</div>-->
			
			<div class="footer-item float-center">
				<div class="footer-blog-posts">
					<h1>Posledné príspevky</h1>
					<BlogPostLast v-for="post in getPosts.slice(0, 3)" :post="post" :key="post.id" :footer="true" />
				</div>
			</div>
			
			
			<div class="footer-item float-right">
				<div class="footer-item-right">
					<h1>Kontakt</h1>
					
					<div class="footer-contact">
						<ul>
							<li>
								<i class="fas fa-map-marker-alt" aria-hidden="true"></i>
								Pezinská 42, 902 01 Vinosady
							</li>
							<li>
								<i class="fas fa-phone" aria-hidden="true"></i>
								<a href="tel:+421905262824">+421 905 262 824</a>
							</li>
							<li>
								<i class="fas fa-envelope" aria-hidden="true"></i>
								<a href="mailto:info@vinokanich.sk">info@vinokanich.sk</a>
							</li>
						</ul>
					</div>
					
					<div class="footer-social">
						<ul>
							<li>
								<a href="https://www.facebook.com/vinokanich" target="_blank">
									<i class="fab fa-facebook-f" aria-hidden="true"></i>
								</a>
							</li>
							<li>
								<a href="https://www.instagram.com/vino_kanich/" target="_blank">
									<i class="fab fa-instagram" aria-hidden="true"></i>
								</a>
							</li>
							<li>
								<a href="" target="_blank">
									<i class="fab fa-youtube" aria-hidden="true"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		
		<div class="footer-copyright">
			Copyright &copy; {{ (new Date()).getFullYear() }} - Víno Kanich
		</div>
	</footer>
</template>

<script>
	// import DarkGoogleMaps from "@/components/DarkGoogleMaps";
	import {mapGetters} from "vuex";
	import BlogPostLast from "./BlogPostLast";
	
	export default {
		name: "Footer",
		components: {BlogPostLast},
		computed: {
			...mapGetters([
				'getPosts',
			]),
		},
	}
</script>

<style scoped lang="scss">
footer {
	background: $color-dark;
	color: $color-text-white;
	z-index: 90;
	
	a, a:hover, a:visited, a:active {
		color: $color-text-white;
	}
	
	.footer-item {
		margin: 0 0 50px;
		flex: 1;
		
		&.float-left {
			align-items: flex-start;
		}
		
		&.float-right {
			margin-left: auto;
			align-items: flex-end;
		}
		
		&.float-center {
			align-content: flex-end;
		}
		
		&-right {
			float: right;
		}
		
		@include media-breakpoint-down(md) {
			flex: 0;
			width: 100%;
			overflow: hidden;
			
			&-right {
				float: none;
				margin: 0 auto;
			}
			
			&.float-left, &.float-right, &.float-center {
				text-align: center;
				margin: 0 auto 50px auto !important;
			}
		}
	}
	
	.footer-main {
		padding: 40px 0 0 0;
		display: flex;
		
		@include media-breakpoint-down(md) {
			display: block;
			overflow: hidden;
			width: 100%;
		}
		
		.fas, .fab, .far {
			color: $color-primary;
		}
		
		a, a:hover, a:visited, a:active {
			text-decoration: none;
		}
		
		h1 {
			color: $color-primary;
			margin-bottom: 15px;
			text-transform: uppercase;
			font-size: $font-size-heading-2;
		}
		
		.footer-nav {
			width: 100%;
			
			ul.navbar-nav {
				.nav-item {
					padding: 0 0 5px 0;
					
					.nav-link {
						padding: 0;
						margin: 0;
						color: $color-text-white;
						@include theme-transition();
						
						&:visited {
							color: $color-text-white;
						}
						
						&:hover {
							color: $color-primary;
						}
					}
				}
			}
		}
		
		.footer-map {
			width: 100%;
			height: 200px;
			float: none;
			margin-bottom: 50px;
			
			iframe {
				width: 100%;
				height: 450px;
			}
			
			@include media-breakpoint-down(md) {
				width: 90%;
				margin: 0 auto 15px auto;
			}
		}
		
		.footer-blog-posts {
			width: 90%;
			margin: 0 5%;
		}
		
		.footer-newsletter {
			width: 100%;
			float: none;
			
			.form-item {
				width: 100%;
				margin: 0 0 15px 0;
				
				label {
					display: block;
					margin: 5px 0 0 5px;
					@include font-primary();
					font-size: $font-size-basic-sm;
					/*font-style: italic;*/
				}
				
				input[type=text], input[type=email] {
					margin: 0;
					width: 100%;
					@include font-primary();
					font-size: $font-size-basic;
					padding: 10px 12px;
					outline: none;
					border: 1px solid $color-text-light;
					@include theme-transition();
					
					&:focus {
						border-color: $color-primary-hover;
					}
				}
				
				input[type=submit] {
					padding: 12.5px 30px;
					@include font-primary();
					font-size: $font-size-basic;
					text-transform: uppercase;
					border: none;
					outline: none;
					background: $color-primary;
					color: $color-text-white;
					@include theme-transition();
					width: 240px;
					
					&:focus, &:hover {
						background: $color-primary-hover;
					}
				}
				
				@include media-breakpoint-down(md) {
					width: 90%;
					margin: 0 auto 15px auto;
					
					input[type=submit] {
						width: 100%;
					}
					
					label {
						display: none;
					}
				}
			}
		}
		
		.footer-contact {
			width: 100%;
			
			ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style: none;
				
				li {
					padding: 0 0 25px 0;
					
					.fas, .fab, .far {
						margin-right: 10px;
					}
				}
			}
		}
		
		.footer-social {
			ul {
				padding-left: 0;
				margin-left: 0;
				list-style: none;
				
				li {
					float: left;
					border-radius: 50%;
					border: 1px solid lighten($color-dark, 2%);
					padding: 0;
					font-size: $font-size-bigger;
					margin: 0 15px 0 0;
					text-align: center;
					width: 45px;
					height: 45px;
					line-height: 45px;
					@include theme-transition();
					
					a {
						padding: 0;
						border-radius: 50%;
						text-align: center;
						display: block;
						width: 100%;
						height: 100%;
						@include theme-transition();
					}
					
					i {
						@include theme-transition();
					}
					
					&:hover, &:focus {
						background: $color-primary;
						color: $color-text-white;
						
						i {
							color: $color-text-white;
						}
					}
				}
			}
			
			@include media-breakpoint-down(md) {
				width: auto;
				margin: 0 auto;
				overflow: auto;
				display: flex;
				justify-content: center;
			}
		}
	}
	
	.footer-copyright {
		background: $color-dark-1;
		color: $color-text-dark;
		text-align: center;
		padding: 25px 0;
		font-size: $font-size-basic-sm;
	}
}
</style>