const state = {
	shops: [],
	redir_after_login: "",
	order: 0,
	title: "",
};

const getters = {
	getShops: state => state.shops,
	getShopsLength: state => state.shops.length,
	getRedirAfterLogin: state => state.redir_after_login,
	getOrderId: state => state.order,
	getTitle: state => state.title,
};

const actions = {};

const mutations = {
	setShops: (state, payload) => state.shops = payload,
	setRedirAfterLogin: (state, payload) => state.redir_after_login = payload,
	setOrderId: (state, payload) => state.order = payload,
	setTitle: (state, payload) => state.title = payload,
};

export default {
	state,
	getters,
	actions,
	mutations
};
