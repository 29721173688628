<template>
	<main role="main" class="container">
		<section class="profile">
			<h1>Profil zákazníka</h1>
			
			<div class="profile-card">
				<div class="profile-card-heading">
					<i class="fas fa-home"></i> Osobné údaje
				</div>
				
				<div class="profile-card-inside">
					<div class="profile-row">
						<div class="profile-row-item">
							<label for="pd_name">Meno</label>
							<div class="input-group" :class="{'invalid': isInvalid('name')}">
								<input type="text" id="pd_name" class="form-control" placeholder="* Meno" v-model="clientData.name">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
						
						<div class="profile-row-item">
							<label for="pd_surname">Priezvisko</label>
							<div class="input-group" :class="{'invalid': isInvalid('surname')}">
								<input type="text" id="pd_surname" class="form-control" placeholder="* Priezvisko" v-model="clientData.surname">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
					</div>
					
					<div class="profile-row">
						<div class="profile-row-item">
							<label for="pd_email">E-mail</label>
							<div class="input-group" :class="{'invalid': isInvalid('email')}">
								<input type="email" id="pd_email" class="form-control" placeholder="* E-mail" v-model="clientData.email">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
						
						<div class="profile-row-item">
							<label for="pd_phone">Telefón</label>
							<div class="input-group" :class="{'invalid': isInvalid('phone')}">
								<input type="text" id="pd_phone" class="form-control" placeholder="* Telefón" v-model="clientData.phone">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="profile-card">
				<div class="profile-card-heading">
					<i class="fas fa-truck"></i> Dodacie a fakturačné údaje
				</div>
				
				<div class="profile-card-inside">
					<!-- Is company? -->
					<div class="profile-row">
						<div class="profile-card-inside-checkbox">
							<input type="checkbox" class="form-check-input" id="is_company" v-model="clientData.company">
							<label class="" for="is_company">Nakupujem na firmu</label>
						</div>
					</div>
					
					<!-- Company data -->
					<div class="profile-row" :class="{'hidden': !clientData.company}">
						<div class="profile-row-item">
							<label for="company_name">Obchodné meno</label>
							<div class="input-group" :class="{'invalid': isInvalid('company_name')}">
								<input type="text" id="company_name" class="form-control" placeholder="* Obchodné meno" v-model="clientData.company_name">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
						
						<div class="profile-row-item">
							<label for="company_ico">IČO</label>
							<div class="input-group" :class="{'invalid': isInvalid('company_ico')}">
								<input type="text" id="company_ico" class="form-control" placeholder="* IČO" v-model="clientData.company_ico">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
					</div>
					
					<div class="profile-row" :class="{'hidden': !clientData.company}">
						<div class="profile-row-item">
							<label for="company_dic">DIČ</label>
							<div class="input-group" :class="{'invalid': isInvalid('company_dic')}">
								<input type="text" id="company_dic" class="form-control" placeholder="* DIČ" v-model="clientData.company_dic">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
						
						<div class="profile-row-item">
							<label for="company_icdph">IČ DPH</label>
							<div class="input-group">
								<input type="text" id="company_icdph" class="form-control" placeholder="IČ DPH" v-model="clientData.company_icdph">
							</div>
						</div>
					</div>
					
					<div class="profile-row" :class="{'hidden': !clientData.company}">
						<hr />
					</div>
					
					<!-- Address-->
					<div class="profile-row">
						<div class="profile-row-item">
							<label for="street">Ulica a číslo</label>
							<div class="input-group" :class="{'invalid': isInvalid('street')}">
								<input type="text" id="street" class="form-control" placeholder="* Ulica a číslo" v-model="clientData.street">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
						
						<div class="profile-row-item">
							<label for="city">Mesto / obec</label>
							<div class="input-group" :class="{'invalid': isInvalid('city')}">
								<input type="text" id="city" class="form-control" placeholder="* Mesto / obec" v-model="clientData.city">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
					</div>
					
					<div class="profile-row">
						<div class="profile-row-item">
							<label for="postal">PSČ</label>
							<div class="input-group" :class="{'invalid': isInvalid('postal')}">
								<input type="text" id="postal" class="form-control" placeholder="* PSČ" v-model="clientData.postal">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
					</div>
					
					<!-- Alternative delivery address? -->
					<div class="profile-row">
						<div class="profile-card-inside-checkbox">
							<input type="checkbox" class="form-check-input" id="alt_delivery" v-model="clientData.delivery_address">
							<label class="" for="alt_delivery">Tovar chcem doručiť na inú adresu</label>
						</div>
					</div>
					
					<!-- Alternative address -->
					<div class="profile-row" :class="{'hidden': !clientData.delivery_address}">
						<hr />
					</div>
					
					<div class="profile-row" :class="{'hidden': !clientData.delivery_address}">
						<div class="profile-row-item">
							<label for="alt_street">Ulica a číslo</label>
							<div class="input-group" :class="{'invalid': isInvalid('delivery_address_street')}">
								<input type="text" id="alt_street" class="form-control" placeholder="* Ulica a číslo" v-model="clientData.delivery_address_street">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
						
						<div class="profile-row-item">
							<label for="alt_city">Mesto / obec</label>
							<div class="input-group" :class="{'invalid': isInvalid('delivery_address_city')}">
								<input type="text" id="alt_city" class="form-control" placeholder="* Mesto / obec" v-model="clientData.delivery_address_city">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
					</div>
					
					<div class="profile-row" :class="{'hidden': !clientData.delivery_address}">
						<div class="profile-row-item">
							<label for="alt_postal">PSČ</label>
							<div class="input-group" :class="{'invalid': isInvalid('delivery_address_postal')}">
								<input type="text" id="alt_postal" class="form-control" placeholder="* PSČ" v-model="clientData.delivery_address_postal">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
						
						<div class="profile-row-item">
							<label for="alt_phone">Telefón</label>
							<div class="input-group" :class="{'invalid': isInvalid('delivery_address_phone')}">
								<input type="text" id="alt_phone" class="form-control" placeholder="Telefón" v-model="clientData.delivery_address_phone">
								<small class="form-text">Táto položka je vyžadovaná.</small>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="profile-card">
				<div class="profile-card-heading">
					<i class="fas fa-lock"></i> Heslo
				</div>

				<div class="profile-card-inside">
					<div class="profile-row">
						<div class="profile-row-item">
							<label for="pd_name">Heslo</label>
							<div class="input-group">
								<input type="password" autocomplete="new-password" id="password" class="form-control" placeholder="* Heslo" v-model="password">
							</div>
						</div>

						<div class="profile-row-item">
							<label for="pd_name">Heslo (znova)</label>
							<div class="input-group">
								<input type="password" autocomplete="new-password" id="password_verify" class="form-control" placeholder="* Heslo (znova)" v-model="password_verify">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="profile-continue">
				<button class="profile-continue-remove" type="button" role="button" @click="removeAccount()">Zmazať účet</button>
				<button type="button" role="button" @click="saveData()">Uložiť</button>
			</div>
		</section>
	</main>
</template>

<script>
import {mapGetters} from 'vuex';
import api from "@/services/api";
import {minLength, required} from "vuelidate/lib/validators";

export default {
	name: "Profile",
	data() {
		return {
			clientData: {
				id: 0,
				name: "",
				surname: "",
				email: "",
				phone: "",
				street: "",
				city: "",
				postal: "",
				company: "",
				company_name: "",
				company_ico: "",
				company_dic: "",
				company_icdph: "",
				delivery_address: "",
				delivery_address_street: "",
				delivery_address_city: "",
				delivery_address_postal: "",
				delivery_address_phone: "",
			},
			password: "",
			password_verify: "",
		};
	},
	validations() {
		//Delivery address validations:
		let delivery_address = {
			delivery_address_street: {
				required,
				minLength: minLength(2),
			},
			delivery_address_city: {
				required,
				minLength: minLength(2),
			},
			delivery_address_postal: {
				required,
				minLength: minLength(4),
			},
			delivery_address_phone: {
				required,
				minLength: minLength(3),
			},
		};
		
		//Company validations:
		let company = {
			company_name: {
				required,
				minLength: minLength(2),
			},
			company_ico: {
				required,
				minLength: minLength(5),
			},
			company_dic: {
				required,
				minLength: minLength(5),
			},
		}
		
		//Company?
		if(!this.clientData.company) {
			company = false;
		}
		
		//Delivery address?
		if(!this.clientData.delivery_address) {
			delivery_address = false;
		}
		
		//Return validations object:
		return {
			clientData: {
				name: {
					required,
					minLength: minLength(3),
				},
				surname: {
					required,
					minLength: minLength(3),
				},
				email: {
					required,
					minLength: minLength(3),
				},
				phone: {
					required,
					minLength: minLength(3),
				},
				street: {
					required,
					minLength: minLength(2),
				},
				city: {
					required,
					minLength: minLength(2),
				},
				postal: {
					required,
					minLength: minLength(4),
				},
				...company,
				...delivery_address,
			}
		};
		
	},
	computed: {
		...mapGetters([
			'getCustomer', 'getCustomerId',
		])
	},
	watch: {
		clientData: {
			handler: function () {
				this.$store.commit('setCustomer', this.clientData);
			},
			deep: true,
		},
		getCustomer: function () {
			this.loadData();
		},
		getCustomerId: function () {
			this.checkLoggedIn();
		},
	},
	mounted() {
		this.loadData();
		this.checkLoggedIn();
	},
	methods: {
		isInvalid(field) {
			return this.$v.clientData[field] && this.$v.clientData[field].$error;
		},
		checkLoggedIn() {
			if(!this.getCustomerId) {
				this.$router.push('/prihlasenie').catch(() => {});
			}
		},
		loadData() {
			this.clientData = this.getCustomer;
		},
		saveData() {
			//Validate:
			this.$v.$touch();
			
			//Is valid?
			if(!this.$v.$invalid) {
				//Create data object:
				let data = {
					customer: this.clientData,
					password: this.password,
					password2: this.password_verify,
				};
				
				//Request:
				let _that = this;
				api.patchCustomer(this.getCustomerId, data).then(res => {
					_that.$store.commit('setCustomer', res.data.customer);
					_that.$swal.fire("Uložené!", "Váš profil bol úspešne upravený!", "success");
					_that.password = _that.password2 = "";
				});
			}
		},
		removeAccount() {
			var _that = this;
			
			this.$swal.fire({
				title: "Zmazať?",
				text: "Skutočne chcete zmazať zákaznícke konto? Túto akciu nie je možné vrátiť späť!",
				icon: 'warning',
				showCancelButton: true,
				showConfirmButton: true,
				confirmButtonText: "Zmazať",
				cancelButtonText: "Ponechať",
			}).then(answer => {
				if(answer.isConfirmed) {
					api.removeCustomerAccount(this.getCustomerId).then(res => {
						if(res.data.ok) {
							_that.$store.commit('setCustomer', null);
						}
					});
				}
			});
		}
	},
}
</script>

<style scoped lang="scss">
	.profile {
		width: 100%;

		$profile-color  : rgba($color-text-dark, 0.15);
		$profile-color-1: rgba($color-text-dark, 0.25);
		$profile-color-2: rgba($color-text-dark, 0.5);
		$item-height: 60px;
		$input-size: 20px;

		&-row {
			margin: 0;
			padding: 7.5px 0;
			overflow: auto;
			@include theme-transition();
			transform-origin: top;
			transform: scaleY(1);
			opacity: 1;
			visibility: visible;
			display: flex;
			
			small.form-text {
				color: $color-error;
				width: 100%;
				display: none;
			}
			
			.input-group.invalid small.form-text {
				display: block;
			}

			hr {
				width: calc(100% - 30px);
				height: 1px;
				margin: 15px auto;
				display: block;
				background: $profile-color-1;
				border: none;
				outline: none;
				float: left;
			}

			&.hidden {
				transform: scaleY(0);
				opacity: 0;
				height: 0;
			}

			&-item {
				padding: 7.5px 15px;
				margin: 0;
				display: inline-block;
				float: left;
				width: 50%;

				&-half {
					padding: 7.5px 15px;
					margin: 0;
					display: inline-block;
					float: left;
					width: 25%;
				}

				&.info {
					font-weight: 400;
					font-size: $font-size-bigger;
					width: 100%;

					strong {
						cursor: pointer;
						font-weight: 400;
						color: $color-primary;
						@include theme-transition();

						&:hover {
							color: $color-primary-hover;
						}
					}
				}

				@include media-breakpoint-down(md) {
					width: 100%;

					&-half {
						width: 50%;
					}
				}
			}
			
			@include media-breakpoint-down(md) {
				display: block;
			}
		}

		&-card {
			width: 100%;
			display: block;
			margin: 0;
			padding: 0;
			overflow: auto;

			&-heading {
				@include font-primary();
				display: block;
				font-size: $font-size-heading-1;
				margin: 0;
				padding: 0 20px;
				line-height: 70px;
				background: $profile-color;

				i {
					margin-right: 15px;
					color: $color-primary;
				}
			}

			&-inside {
				padding: 0 15px;
				margin: 0;
				border: 1px solid $profile-color;

				&-checkbox {
					margin: 0;
					padding: 7.5px 15px;
					width: 100%;
					display: block;
					overflow: auto;
					cursor: pointer;

					label {
						display: inline-block;
						float: left;
						margin: 0 0 0 15px;
						padding: 0;
						user-select: none;
						cursor: pointer;
					}

					input[type="checkbox"] {
						width: 20px;
						height: 20px;
						cursor: pointer;
						margin: 0;
						border: 1px solid $profile-color-2;
						padding: 0;
						position: relative;
						float: left;
						display: inline-block;


						&:focus {
							outline: none;
						}

						&:checked {
							border-color: $color-primary;
							/*background: rgba($color-primary, 0.1);*/

							&:after {
								content: "";
								position: absolute;
								top: 1px;
								left: 6px;
								width: 6px;
								height: 12px;
								display: block;
								transform: rotateZ(45deg);
								border-bottom: 1px solid $color-primary;
								border-right: 1px solid $color-primary;
							}
						}
					}
				}
			}

			@include media-breakpoint-down(md) {
				&-heading {
					font-size: $font-size-bigger;
					padding: 0 15px;
				}
			}
		}

		&-total {
			width: 100%;
			margin: 0;

			&-inner {
				float: right;
				display: block;
				overflow: visible;
				padding: 0 20px;
				margin: 30px 0 0;

				h1, h2 {
					@include font-primary();
					font-size: $font-size-bigger;
					color: $color-dark;
					display: block;
					width: 300px;
					text-align: left;

					span {
						@include font-primary(500);
						width: 150px;
						font-size: $font-size-heading-1;
						color: $color-primary;
						display: inline-block;
						text-align: right;
						float: right;
					}
				}

				h2 {
					span {
						font-weight: 400;
						font-size: $font-size-heading-1;
					}
				}

				@include media-breakpoint-down(md) {
					width: 100%;
					padding: 0 10px;
					float: none;

					h1, h2 {
						width: 100%;
						text-align: left;
						font-size: $font-size-basic-lg;

						span {
							width: auto;
							font-size: $font-size-bigger;
						}
					}
				}
			}
		}

		&-continue {
			width: 100%;
			overflow: auto;

			button, a {
				background: $color-primary;
				color: $color-text-white;
				padding: 12.5px 30px;
				@include font-primary(400);
				font-size: $font-size-bigger;
				text-transform: uppercase;
				float: right;
				outline: none;
				text-decoration: none;
				border: 1px solid $color-primary;
				@include theme-transition();

				&:hover {
					background: $color-primary-hover;
					border: 1px solid $color-primary-hover;
				}
			}
			
			&-remove {
				background: none !important;
				float: left !important;
				color: $color-primary !important;
				
				&:hover {
					background: $color-primary-hover !important;
					border: 1px solid $color-primary-hover !important;
					color: $color-text-white !important;
				}
			}
		}
	}
</style>