<template>
	<section class="shopping-cart-step1">
		<div class="shopping-cart">
			<div class="alert alert-warning mb-5" v-if="!canContinue">
				Vína je možné objednať len ako kartón, teda po 6ks v balení. Chýba Vám {{ 6 - canContinueItems }}ks pre pokračovanie.
			</div>

			<ProductCartItem v-for="item in getCartProducts" :key="item.product.id" :item="item" />

			<div class="shopping-cart-subtotal">
				<h1>Medzisúčet: <span>{{ $parent.formatPrice(getSubtotal) }}</span></h1>
			</div>

			<div class="shopping-cart-continue">
				<button type="button" role="button" @click="continueToNextStep" :class="{'continue': !canContinue}">Pokračovať</button>

				{{ canContinue }}
			</div>
		</div>
	</section>
</template>

<script>
	import ProductCartItem from "../../components/ProductCartItem";
	import {mapGetters} from 'vuex';

	export default {
		name: "ShoppingCartStep1",
		components: {ProductCartItem},
		mounted() {
			this.reloadContinue();
		},
		data() {
			return {
				amount: 1,
				canContinue: false,
				canContinueItems: 0,
			};
		},
		computed: {
			...mapGetters([
				'getSubtotal', 'getCartProducts', 'getCartProductsCountItems',
			])
		},
		watch: {
			getCartProducts: {
				deep: true,
				handler() {
					this.$emit("reloadProducts", true);
					this.reloadContinue();
				},
			}
		},
		methods: {
			reloadContinue() {
				this.canContinueItems = this.getCartProductsCountItems % 6
				this.canContinue = this.canContinueItems === 0;
			},
			continueToNextStep() {
				if(this.canContinue) {
					this.$parent.nextStep(1);
				}
			},
		},
	}
</script>

<style scoped lang="scss">
	.shopping-cart-step1 {
		.shopping-cart {
			width: 100%;

			&-subtotal {
				width: 100%;
				margin: 30px 0;
				text-align: right;

				h1 {
					@include font-primary();
					font-size: $font-size-heading-1;
					color: $color-dark;

					span {
						@include font-primary(500);
						font-size: $font-size-heading-2;
						color: $color-primary;
						margin-left: 25px;
						display: inline-block;
					}
				}
			}

			&-continue {
				width: 100%;
				overflow: auto;

				button, a {
					background: $color-primary;
					color: $color-text-white;
					padding: 12.5px 30px;
					@include font-primary(400);
					font-size: $font-size-bigger;
					text-transform: uppercase;
					float: right;
					border: none;
					outline: none;
					text-decoration: none;
					@include theme-transition();

					&:hover {
						background: $color-primary-hover;
					}

					&.continue {
						background: $color-text-dark;
						cursor: not-allowed;

						&:hover {
							background: $color-text-dark;
						}
					}
				}
			}
		}
	}
</style>
